import React from "react";
import { Row, Col } from "react-bootstrap";
import NumberFormat from "react-number-format";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

//Components
import CheckboxComponent from "../CustomUIComponents/CheckboxComponent/CheckboxComponent";

//Constants
import { FREQUENCY_LIST } from "./Constants";
//Strings
import { Strings } from "../../resources/i18n/i18n";

//Assets

import Utility from "../../utils/Utility";

const PaymentFrequenciesConfiguration = (props) => {

    const levelConfig = props.configurationData.configurationObject.levelConfig;
    const emptyLevelConfigurationData =
        props.emptyLevelConfigurationData;

    const stylesTableCell = () => {
        return styled(TableCell)(({ theme }) => {
            return {
                [`&.${tableCellClasses.head}`]: {
                    fontWeight: "bold",
                    backgroundColor: theme.palette.action.hover,
                    color: theme.palette.common.black,
                },
                [`&.${tableCellClasses.body}`]: {
                    fontSize: 14,
                },
            };
        });
    };

    const isCellWithError = (
        levelConfigIdx,
        loanTypeIndex,
        sectionKeyIndex,
        subSectionKeyIndex,
        checkType,
    ) => {
        let className = "form-control w-100"
        let errorFieldList = emptyLevelConfigurationData;
        for (let element of errorFieldList) {
            if (
                element.levelConfigIdx === levelConfigIdx &&
                element.loanTypeIndex === loanTypeIndex &&
                element.sectionKeyIndex === sectionKeyIndex &&
                element.subSectionKeyIndex === subSectionKeyIndex
            ) {
                className += " error-data";
            }
        }

        return className;
    };

    const onCheckboxSelection = (levelIndex, value, loanType, sectionKey, subSectionKey) => {
        let levelDetails = levelConfig[levelIndex];
        let loanTypeObject = levelDetails.loanType?.find(loan => loan.type === loanType);
        let section = loanTypeObject[sectionKey];
        let subSection = section[subSectionKey];
        let frequencies = subSection.frequencies || [];
        if (frequencies.includes(value)) {
            frequencies = frequencies.filter(freq => freq !== value);
        } else {
            frequencies.push(value);
        }
        subSection.frequencies = frequencies;
        props.handleLevelConfigChange(levelConfig);
    };
    const onThresholdLoanAmount = (levelIndex, event) => {
        let levelDetails = levelConfig[levelIndex];
        let loanTypeObject = levelDetails.loanType.find(loan => loan.type === event.target.loanType);
        let sectionKey = event.target.sectionKey;
        let subSectionKey = event.target.subSectionKey;
        let section = loanTypeObject[sectionKey];
        let subSection = section[subSectionKey];
        subSection.minAmount = event.target.value;
        props.handleLevelConfigChange(levelConfig);

        if (event.target.name === "amount") {
            props.isAllLevelConfigurationDataFilled(
                { levelConfig },
                "levelConfig",
                "levelConfig"
            );
        }
    };

    const StyledTableCell = stylesTableCell();
    return (
        <div className='emiConfiguration'>
            {levelConfig.map((levelDetails, levelIndex) => (
                <Row key={levelDetails.level}>
                    <Col md={12} className='pb-3'>
                        <Accordion defaultExpanded={levelIndex === 0}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls='panel1a-content'
                                id='panel1a-header'
                                className='emiAccordianSummary'
                            >
                                <Typography component={"span"}>
                                    {Strings(levelDetails.level)}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {levelDetails.loanType.map((loanType, loanTypeIndex) => (
                                    <Row>
                                        <Col md={12} className="pb-3">
                                            <Accordion
                                                defaultExpanded={loanTypeIndex === 0}
                                                className='emiAccordian'
                                            >
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls='panel1a-content'
                                                    id='panel1a-header'
                                                >
                                                    <Typography component={"span"}>
                                                        {Strings(loanType.type)}
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    {Object.keys(loanType).filter(key => key !== 'type').map((sectionKey, sectionKeyIndex) => (
                                                        <Row key={sectionKey}>
                                                            <Col md={12} className="pb-3">
                                                                <TableContainer component={Paper}>
                                                                    <Table aria-label='customized table'>
                                                                        <TableHead>
                                                                            <TableRow>
                                                                                <StyledTableCell>
                                                                                    <Typography component={"span"}>
                                                                                        {Strings(sectionKey)}
                                                                                    </Typography>
                                                                                </StyledTableCell>
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        <TableBody>
                                                                            {Object.keys(loanType[sectionKey]).map((subSectionKey, subSectionKeyIndex) => (
                                                                                <TableRow key={subSectionKey}>
                                                                                    <TableCell
                                                                                        component='th'
                                                                                        scope='row'
                                                                                        onKeyDown={Utility.allowOnlyNumber}
                                                                                    >
                                                                                        <Row>
                                                                                            <Col md={12} className='px-0 py-2'>
                                                                                                <Typography component={"span"}>
                                                                                                    {Strings(subSectionKey)}
                                                                                                </Typography>
                                                                                            </Col>
                                                                                            <Col md={12} className='px-0 py-3'>
                                                                                                <strong className='subText'>
                                                                                                    {Strings("frequency")}
                                                                                                </strong>
                                                                                            </Col>
                                                                                            {FREQUENCY_LIST.map((frequency, frequencyIndex) => (
                                                                                                <Col
                                                                                                    md={2}
                                                                                                    key={levelDetails.level + levelIndex + frequencyIndex + frequency.value}
                                                                                                >
                                                                                                    <CheckboxComponent
                                                                                                        onChange={() => {
                                                                                                            if (!props.readOnly && frequency.value !== "WEEKLY_THRICE") {
                                                                                                                onCheckboxSelection(
                                                                                                                    levelIndex,
                                                                                                                    frequency.value,
                                                                                                                    loanType.type,
                                                                                                                    sectionKey,
                                                                                                                    subSectionKey
                                                                                                                );
                                                                                                            }
                                                                                                        }}
                                                                                                        checked={loanType[sectionKey][subSectionKey].frequencies.includes(frequency.value)}
                                                                                                        disabled={props.readOnly}
                                                                                                        label={Strings(frequency.label)}
                                                                                                        key={Strings(frequency.label)}
                                                                                                    />
                                                                                                </Col>
                                                                                            ))}
                                                                                        </Row>
                                                                                        <Row>
                                                                                            <Col md={12} className='px-0 py-3'>
                                                                                                <strong className="subText">
                                                                                                    {Strings("thresholdLoanAmount")}
                                                                                                </strong>
                                                                                            </Col>
                                                                                            <Col md={3}>
                                                                                                <NumberFormat
                                                                                                    name='term'
                                                                                                    className={isCellWithError(
                                                                                                        levelIndex,
                                                                                                        loanTypeIndex,
                                                                                                        sectionKeyIndex,
                                                                                                        subSectionKeyIndex,
                                                                                                    )}
                                                                                                    onValueChange={(event) => {
                                                                                                        onThresholdLoanAmount(levelIndex, {
                                                                                                            target: {
                                                                                                                name: "amount",
                                                                                                                value: event.value ? event.value : "",
                                                                                                                loanType: loanType.type,
                                                                                                                sectionKey: sectionKey,
                                                                                                                subSectionKey: subSectionKey
                                                                                                            }
                                                                                                        });
                                                                                                    }}
                                                                                                    allowLeadingZeros={false}
                                                                                                    value={loanType[sectionKey][subSectionKey].minAmount}
                                                                                                    type='text'
                                                                                                    placeholder={Strings("thresholdLoanAmount")}
                                                                                                    allowNegative={false}
                                                                                                    disabled={props.readOnly}
                                                                                                />
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            ))}
                                                                        </TableBody>
                                                                    </Table>
                                                                </TableContainer>
                                                            </Col>
                                                        </Row>
                                                    ))}

                                                </AccordionDetails>
                                            </Accordion>
                                        </Col>
                                    </Row>
                                ))}

                            </AccordionDetails>
                        </Accordion>
                    </Col>
                </Row>
            ))}
        </div>
    );

};
export default PaymentFrequenciesConfiguration;
