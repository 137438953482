import React from "react";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import moment from "moment";
import _ from "lodash";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

//Component
import LoanApplicationHeader from "./LoanApplicationHeader";
import AnalystInput from "./AnalystInput";
import NegotiationComponent from "./NegotiationComponent";
import AnalystActionButtons from "./AnalystActionButtons";
import OperationsOfficersInput from "./OperationsOfficersInput";
import UserModal from "../CustomUIComponents/UserModal/UserModal";
import NotesModal from "../CustomUIComponents/NotesModal/NotesModal";
import Loans from "../Loans/Loans";
import ApprovalMinimart from "./ApprovalMinimart";
import ViewLoanApplication from "./ViewLoanApplication";
import EditableModal from "../LoanDetails/EditableModal";
import CheckboxComponent from "../CustomUIComponents/CheckboxComponent/CheckboxComponent";

//Action

//Constants
import {
  ANALYST_ACTION,
  LOAN_TYPES,
  EVIDENCE_TYPES,
  OPERATIONS_OFFICER_ACTION,
  ANALYSED_BY_SYSTEM,
  CONCILIATION_TABLE_HEADERS,
} from "./Constants";
import {
  CONFIGURATION_DATE_TIME_FORMAT,
  DEFAULT_LOAN_STATUS,
  DEFAULT_DURATION,
  CATEGORY,
  DEFAULT_LOAN_STATUS_SELECTION,
  DEFAULT_DATE_FORMAT,
  STATUS_FOR_LOANS,
  DISBURSED,
  DATE_TYPE,
  SEARCH_FILTER_TYPE,
  SEARCH_FILTER_FIELDS,
  EVIDENCE,
  USERS,
  ALLOWED_LOAN_TYPES_NEGOTIATION,
  FILE_TYPE,
  FILE_CONFIGURATIONS,
  TRANSFER_RECEIPT_FILE_SIZE,
} from "../../constants/appConstants";
import { DEFAULT_PAGINATION_ACTIVE_PAGE } from "../CustomUIComponents/Pagination/PaginationConstants";

//Strings
import { Strings } from "../../resources/i18n/i18n";

//Utility
import Utility from "../../utils/Utility";

//Styles
import "./AnalystApproval.scss";

//Assets

class AnalystApproval extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      creditScoreObject: null,
      errorMessage: "",
      note: "",
      noteType: "",
      notesModal: false,
      status: "",
      savedNegotiation: null,
      errorForNegotiation: "",
      showUserModal: false,
      accountsDraftedData: null,
      operationsDraftedData: null,
      creditScore: { cs: "" },
      showLowCreditScoreMsg: false,
      loanFilters: {
        status: props.searchFilter.st,
        fromDate: props.searchFilter.fd,
        category: props.searchFilter.ct,
        toDate: props.searchFilter.td,
        loanId: props.loanHeaderDetails?.alid,
      },
      recordsPerPage: 4,
      activePage: props.searchFilter.offset,
      disabled: props.searchFilter.st !== DISBURSED,
      loanStatuses: STATUS_FOR_LOANS,
      fromDate: DEFAULT_DURATION.fromDate,
      toDate: DEFAULT_DURATION.toDate,
      loansCollapse: false,
      deletedEvidence: null,
      deleteEvidenceMessage: "",
      isReject: false,
      visitDayErrorMessage: "",
      isInsufficientCreditLimit: false,
      insufficientCreditLimitLoans: "",
      remittanceDeliveryAmount: 0,
      remittanceAmount: 0,
      showEditUserModal: false,
      remittanceSector: "",
      remittanceStreet: "",
      remittanceNumber: "",
      remittanceReference: "",
      remittanceCity: "",
      remittanceTelNumber: "",
      remittanceExtraTelNumber: "",
      loanAccordionOpen: false,
      conciliationDetails: this.props.loanHeaderDetails?.cld,
      orderId: props.loanHeaderDetails?.info.oid,
      operationInputError: "",
      ownerCedulaId: null,
      transferReceipt: {},
      showLoansToConciliateWith: false,
      selectedLoansToConciliate: []
    };
    this.negotiationRef = React.createRef();
  }

  static getDerivedStateFromProps(props, state) {
    let derivedState = {};
    if (
      props.creditScore.cs !== state.creditScore.cs &&
      props.loanHeaderDetails
    ) {
      derivedState = {
        creditScore: props.creditScore,
      };
    }

    if (
      props.analystPrefilledData &&
      state.creditScoreObject === null
    ) {
      let propAnalystData = props.analystPrefilledData;
      delete propAnalystData.refNotes;
      delete propAnalystData.profileNotes;
      delete propAnalystData.regulatorsNotes;
      derivedState = {
        creditScoreObject: propAnalystData,
        errorForNegotiation: "",
      };
    }
    if (props.draftedNegotiation && state.savedNegotiation === null) {
      derivedState = {
        savedNegotiation: {
          amount:
            props.draftedNegotiation.amount !== 0
              ? props.draftedNegotiation.amount
              : "",
          term:
            props.draftedNegotiation.term !== 0
              ? props.draftedNegotiation.term
              : "",
          frequency:
            props.draftedNegotiation.frequency !== 0
              ? props.draftedNegotiation.frequency
              : "",
        },
        errorForNegotiation: "",
      };
    }
    if (
      props.accountsDraftedData &&
      state.accountsDraftedData === null &&
      props.accountsDraftedData.cashDeliveryConfirmed
    ) {
      derivedState = {
        accountsDraftedData: props.accountsDraftedData,
      };
    }
    return derivedState;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.errorMessage !== this.props.errorMessage) {
      this.setState({
        errorMessage: this.props.errorMessage,
        errorForNegotiation: this.props.errorMessage,
      });
    }
    if (
      prevProps.loanHeaderDetails?.alid !==
      this.props.loanHeaderDetails?.alid
    ) {
      this.setState({
        loanFilters: {
          ...this.state.loanFilters,
          loanId: this.props.loanHeaderDetails?.alid
            ? this.props.loanHeaderDetails?.alid
            : this.props.searchFilter.lid,
        },
      });
    }
    if (!_.isEqual(prevProps.searchFilter, this.props.searchFilter)) {
      this.setState({
        loanFilters: {
          status: this.props.searchFilter.st,
          fromDate: this.props.searchFilter.fd,
          category: this.props.searchFilter.ct,
          toDate: this.props.searchFilter.td,
          loanId: this.props.searchFilter.lid,
        },
      });
    }
    if (
      !_.isEqual(
        prevProps.loanHeaderDetails?.cld,
        this.props.loanHeaderDetails?.cld
      )
    ) {
      this.setState({
        conciliationDetails: this.props.loanHeaderDetails?.cld

      });
    }
    if (
      prevProps.loanHeaderDetails?.info.oid !==
      this.props.loanHeaderDetails?.info.oid
    ) {
      this.setState({
        orderId: this.props.loanHeaderDetails?.info.oid,
      });
    }
  }
  checkValidFields = (creditScoreObject) => {
    let valid = true;
    if (
      creditScoreObject.profileAnalysis === null ||
      creditScoreObject.regulatorsAnalysis === null ||
      creditScoreObject.refAnalysis === null ||
      creditScoreObject.profileAnalysis.risk === "" ||
      creditScoreObject.regulatorsAnalysis.classification === "" ||
      creditScoreObject.regulatorsAnalysis.worstArrears === "" ||
      creditScoreObject.regulatorsAnalysis.largestLoanAmount === "" ||
      creditScoreObject.refAnalysis.personalRefVerified === "" ||
      creditScoreObject.refAnalysis.commercialRefVerified === "" ||
      creditScoreObject.refAnalysis.neighbourRefVerified === ""
    ) {
      valid = false;
    }
    return valid;
  };

  checkNegotiation = (negotiationData) => {
    let valid = true;

    if (
      !negotiationData.term ||
      !negotiationData.amount ||
      !negotiationData.frequency ||
      !negotiationData.collectionDays) {
      valid = false;
    }
    return valid;
  };

  calculateCreditScore = (creditScoreObject) => {
    let valid = false;
    valid = this.checkValidFields(creditScoreObject);
    let directReject = false;
    directReject = this.checkIfbothRefAreNegative(
      creditScoreObject.refAnalysis
    );

    if (valid && !directReject) {
      this.setState(
        {
          creditScoreObject: { ...creditScoreObject },
          errorMessage: "",
        },
        () => {
          let requestObjecct = {
            refAnalysis: { ...creditScoreObject.refAnalysis },
            profileAnalysis: { ...creditScoreObject.profileAnalysis },
            regulatorsAnalysis: {
              ...creditScoreObject.regulatorsAnalysis,
            },
          };
          this.props.calculateCreditScore(requestObjecct);
        }
      );
    } else if (directReject && valid) {
      let requestObjecct = {
        refAnalysis: { ...creditScoreObject.refAnalysis },
        profileAnalysis: { ...creditScoreObject.profileAnalysis },
        regulatorsAnalysis: {
          ...creditScoreObject.regulatorsAnalysis,
        },
      };
      this.props.calculateCreditScore(requestObjecct, () => {
        this.setState({
          showUserModal: true,
          showLowCreditScoreMsg: false,
          creditScoreObject: { ...creditScoreObject },
        });
      });
    } else {
      this.setState({
        errorMessage: Strings("pleaseSelectRequiredFields"),
      });
    }
  };

  showinsufficientCreditLimitMessage = (loans) => {
    this.setState({
      showUserModal: true,
      isInsufficientCreditLimit: true,
      insufficientCreditLimitLoans: loans,
    });
  };

  creditScoreValidation = (status) => {
    if (this.state.creditScoreObject) {
      if (
        (status === ANALYST_ACTION.APPROVE ||
          status === ANALYST_ACTION.APPROVE_WITH_CONCILIATION) &&
        this.props.loanHeaderDetails?.info.ty !== LOAN_TYPES.FAST_CASH &&
        this.props.loanHeaderDetails?.info.ty !== LOAN_TYPES.FACTORING
      ) {
        return this.checkValidFields(this.state.creditScoreObject);
      }
      return true;
    }
    return false;
  };

  conciliationValidation = (status) => {
    if (status === ANALYST_ACTION.APPROVE_WITH_CONCILIATION) {
      if (this.state.savedNegotiation.amount) {
        const totalAmt = this.props.loanHeaderDetails.td?.reduce((acc, item) => {
          return acc + item.upc + item.upi + item.uplf;
        }, 0);
        return (
          this.state.savedNegotiation.amount > totalAmt
        );
      } else return false;
    }
    return true;
  };

  updateNegotiation = (status) => {
    let negotiationData = this.negotiationRef.current.getNegotiationdata();
    let valid = this.checkNegotiation(negotiationData);
    let isAmountvalid =
      0 <
      parseInt(negotiationData.amount) <
      parseInt(this.props.loanHeaderDetails?.info["amt"]);
    if (valid && isAmountvalid) {
      this.setState(
        {
          errorForNegotiation: "",
        },
        () => {
          this.updateLoanNegotiationAndTerms(negotiationData, status);
        }
      );
    } else {
      if (!isAmountvalid) {
        this.setState({
          errorForNegotiation: Strings("pleaseEnterCorrectAmount"),
        });
      } else {
        this.setState({
          errorForNegotiation: Strings("pleaseEnterValidNegotiationData"),
        });
      }
    }
  };

  //close notes modal and clear note variable after api call
  closeNotesModal = () => {
    this.setState({
      notesModal: false,
      note: ""
    });
  }

  resetErrorForNegotiation = () => {
    this.setState(
      {
        errorForNegotiation: "",
      },
      () => {
        this.props.resetError();
      }
    );
  };

  toggleConciliationModal = (isCancel = false) => {
    this.setState({
      showLoansToConciliateWith: !this.state.showLoansToConciliateWith,
      selectedLoansToConciliate: isCancel ? [] : this.state.selectedLoansToConciliate
    })
  }

  showLowCreditScoreWarning = () => {
    this.setState({
      showUserModal: true,
      showLowCreditScoreMsg: true,
    });
  }

  openConfirmationPopup = () => {
    this.setState({
      showUserModal: true,
      isReject: true,
    })
  }

  //Analyst actions
  onClickApprove = () => {
    //If references are negative show error for other than Factoring CI and Fast Cash loan types.
    if (this.props.loanApplicationDetails.invalidFormField.length > 0) {
      this.setState({
        errorForEmptyFields: Strings("formValuesNotPresentInConf"),
      }, this.scrollToTop);
    } else {
      if (
        this.props.loanHeaderDetails?.info.ty !== LOAN_TYPES.FAST_CASH
        && this.props.loanHeaderDetails?.info.ty !== LOAN_TYPES.FACTORING
        && (!this.state.creditScoreObject?.refAnalysis || (this.state.creditScoreObject.refAnalysis
          && this.checkIfbothRefAreNegative(this.state.creditScoreObject.refAnalysis)))
      ) {
        if (this.checkIfLowCreditScore()) {
          this.setState({
            showUserModal: true,
            showLowCreditScoreMsg: true,
            loanStatusToBeUpdated: ANALYST_ACTION.REJECT,
          });
        } else {
          this.setState({
            showUserModal: true,
            showLowCreditScoreMsg: false,
            loanStatusToBeUpdated: ANALYST_ACTION.REJECT,
          });
        }
      }
      else {
        //Proceed with analyst inputs.
        //Proceed without analyst inputs for Fast cash and Factoring CI loan types.
        if (this.props.loanHeaderDetails?.info.ty === LOAN_TYPES.FACTORING
          || this.props.loanHeaderDetails?.info.ty === LOAN_TYPES.FAST_CASH) {
          this.updateNegotiation(ANALYST_ACTION.APPROVE);
        }
        else {
          let valid = this.checkValidFields(this.state.creditScoreObject);
          if (valid) {
            if (this.state.loanStatusToBeUpdated === ANALYST_ACTION.APPROVE_WITH_CONCILIATION) {
              this.props.getLoanDetailsForConciliation(this.toggleConciliationModal);
            } else {
              this.updateNegotiation(ANALYST_ACTION.APPROVE);
            }
          }

        }
      }
    }
  }

  onClickIncomplete = () => {
    this.setState({
      showUserModal: false,
      noteType: ANALYST_ACTION.INCOMPLETE,
      notesModal: true,
    });
  }

  onClickReject = () => {
    this.setState({
      showUserModal: true,
      isReject: true,
    });
  }

  //Operations Officer actions
  onClickDisburse = () => {
    if (this.props.loanHeaderDetails?.info.ty === LOAN_TYPES.FACTORING
      || this.props.loanHeaderDetails?.info.ty === LOAN_TYPES.FAST_CASH) {
      this.negotiateAndDisburse(OPERATIONS_OFFICER_ACTION.DISBURSE);
    } else {
      this.setState({
        showUserModal: true,
      });
    }
  }

  toggleModal = (status) => {
    this.setState({
      loanStatusToBeUpdated: status,
    }, () => {
      switch (status) {
        case ANALYST_ACTION.APPROVE:
          this.onClickApprove();
          break;
        case ANALYST_ACTION.APPROVE_WITH_CONCILIATION:
          this.onClickApprove();
          break;
        case ANALYST_ACTION.INCOMPLETE:
          this.onClickIncomplete();
          break;
        case ANALYST_ACTION.REJECT:
          this.onClickReject();
          break;
        case OPERATIONS_OFFICER_ACTION.DISBURSE:
          this.onClickDisburse();
          break;
        default:
          break;
      }
    })
  };

  addNote = (note = null, noteType = null, ownerCedulaId = null) => {
    this.setState({
      note: note,
      noteType: noteType ? noteType : "",
      ownerCedulaId: ownerCedulaId,
    }, this.onClickOfAcceptNotes);

  };

  resetError = () => {
    this.setState(
      { errorMessage: "", errorForNegotiation: "" },
      () => {
        this.props.resetError();
      }
    );
  };

  editNote = (note, noteId, noteType) => {
    this.props.editNote(note, noteId, noteType);
  };

  updateLoanNegotiationAndTerms = (savedNegotiation, status) => {
    this.setState(
      { savedNegotiation: savedNegotiation, errorForNegotiation: "" },
      () => {
        this.props.updateLoanNegotiationAndTerms(
          this.state.savedNegotiation,
          status,
          this.onConfirmApprove
        );
      }
    );
  };

  checkIfbothRefAreNegative = (References) => {
    let allRefNegative = false;
    if (
      References.personalRefVerified === false &&
      References.commercialRefVerified === false &&
      References.neighbourRefVerified === false
    ) {
      allRefNegative = true;
    }
    return allRefNegative;
  };

  negotiateAndDisburse = (status) => {
    let valid = this.creditScoreValidation(status);
    if (valid
      || this.props.loanHeaderDetails?.info.ty === LOAN_TYPES.FAST_CASH
      || this.props.loanHeaderDetails?.info.ty === LOAN_TYPES.FACTORING
      || this.props.loanHeaderDetails?.association.aa === ANALYSED_BY_SYSTEM
    ) {
      this.updateNegotiation(status);
    }
  }

  onComfirmClickOfConfirmationModal = () => {
    this.setState({
      showUserModal: false,
      showLoansToConciliateWith: false,
      notesModal: this.state.loanStatusToBeUpdated !== OPERATIONS_OFFICER_ACTION.DISBURSE,
      loanStatusToBeUpdated: this.state.isInsufficientCreditLimit
        ? ANALYST_ACTION.REJECT
        : this.state.loanStatusToBeUpdated
    }, () => {
      if (this.state.loanStatusToBeUpdated === ANALYST_ACTION.APPROVE_WITH_CONCILIATION) {
        this.updateNegotiation(ANALYST_ACTION.APPROVE_WITH_CONCILIATION);
      }
    });
  }

  renderConfirmationModal = () => {//NOSONAR
    let editableData = this.state?.conciliationDetails;
    const cld = editableData?.map((item) => {
      const td = item.upc + item.uplf + item.upi;
      return {
        aid: item.aid,
        upc: item.upc,
        upi: item.upi,
        uplf: item.uplf,
        td
      };
    });
    const totalTd = cld?.reduce((sum, item) => sum + item.td, 0);
    const totalApamt = editableData?.reduce((sum, item) => sum + item.apamt, 0);
    const apamt = editableData?.length > 1 ? totalApamt / editableData?.length : totalApamt;
    const atm = apamt - totalTd;
    const requestData = {
      cld: cld,
      apamt: apamt,
      atm: atm
    };
    let modalText1;
    let modalText2;
    let onClickEvent = this.onComfirmClickOfConfirmationModal;
    let headerText;
    if (
      this.props.isOperationsOfficer ||
      this.props.isOperationsModerator ||
      this.props.isOperationsManager
    ) {
      if (this.state.isReject) {
        headerText = Strings("RejectNotification");
        modalText1 = Strings("confirmMessage");
      } else {
        modalText1 = Strings("remittanceIsPending");
        onClickEvent = () => {
          this.setState({
            showUserModal: false,
          });
          this.props.loanHeaderDetails?.info.ty === LOAN_TYPES.CASH_TOPUP &&
            this.props.loanHeaderDetails?.cld.length > 0
            ? this.props.saveConciliation(
              requestData,
              () => {
                this.negotiateAndDisburse(OPERATIONS_OFFICER_ACTION.DISBURSE,)
              }
            )
            :
            this.negotiateAndDisburse(OPERATIONS_OFFICER_ACTION.DISBURSE,)
        };
        headerText = Strings("loanDisbursement");
      }
    } else {
      if (this.state.deletedEvidence) {
        headerText = Strings("DeleteEvidence");
        onClickEvent = () => this.handleDeleteEvidenceAction();
        modalText1 = this.state.deleteEvidenceMessage;
        modalText2 = Strings("");
      } else if (this.state.showLowCreditScoreMsg) {
        modalText1 = Strings("lowCreditScoreLoanRejectionWarning");
        modalText2 = Strings("minimartBarredWarning");
        headerText = Strings("RejectNotification");
      } else if (this.state.isReject) {
        headerText = Strings("RejectNotification");
        modalText1 = Strings("confirmMessage");
      } else if (this.state.isInsufficientCreditLimit) {
        headerText = Strings("RejectNotification");
        modalText1 =
          Strings("ICL_ERROR_MSG") +
          this.state.insufficientCreditLimitLoans;
        modalText2 = Strings("doYouWantToContinue");
      } else {
        headerText = Strings("RejectNotification");
        modalText1 = Strings("doYouReallyWantToProceed");
        modalText2 = Strings("bothReferencesAreMarkedNegative");
      }
    }
    return (
      <UserModal
        open={this.state.showUserModal}
        modalCloseOnEsc={false}
        modalCloseOnOverlayClick={false}
        modalShowCloseIcon={false}
        headerText={headerText}
        modalText1={modalText1}
        modalText2={modalText2}
        modalButtonOk={{
          text: Strings("Yes"),
          onClick: onClickEvent,
        }}
        modalButtonCancel={
          this.state.isInsufficientCreditLimit
            ? (this.props.loanHeaderDetails?.info.ty === LOAN_TYPES.CASH_TOPUP && this.props.loanHeaderDetails.alid)
              ? {
                text: Strings("No"),
                onClick: this.closeUserModal,
              }
              : false
            : {
              text: Strings("No"),
              onClick: this.closeUserModal,
            }
        }
        showHr={true}
      />
    );
  };

  closeUserModal = () => {
    this.setState({
      showUserModal: false,
      showLowCreditScoreMsg: false,
      deletedEvidence: null,
      deleteEvidenceMessage: "",
      isReject: false,
      isInsufficientCreditLimit: false,
      insufficientCreditLimitLoans: "",
    });
  };

  checkIfLowCreditScore = () => {
    let lowCreditScore = false;
    if (
      (this.props.loanHeaderDetails?.info.ty === LOAN_TYPES.CASH_TOPUP &&
        this.state.creditScore.cs < this.state.creditScore.ecs) ||
      (this.props.loanHeaderDetails?.info.ty === LOAN_TYPES.CONSUMER &&
        this.state.creditScore.cs < this.state.creditScore.ecs) ||
      (this.props.loanHeaderDetails?.info.ty === LOAN_TYPES.EXTERNAL_FACTORING &&
        this.state.creditScore.cs < this.state.creditScore.ecs) ||
      (this.props.loanHeaderDetails?.info.ty === LOAN_TYPES.TU_PRESTAMO &&
        this.state.creditScore.cs < this.state.creditScore.ecs) ||
      (this.props.loanHeaderDetails?.info.ty === LOAN_TYPES.MOTORCYCLE_LOAN &&
        this.state.creditScore.cs < this.state.creditScore.ecs)
    ) {
      lowCreditScore = true;
    }
    return lowCreditScore;
  };

  handleChangeStatusFilter = (event) => {
    if (event.target.value !== DEFAULT_LOAN_STATUS_SELECTION.value) {
      this.setState({
        loanFilters: {
          ...this.state.loanFilters,
          status: event.target.value,
          category:
            event.target.value !== DISBURSED
              ? ""
              : this.state.loanFilters.category,
        },
        disabled: event.target.value === DISBURSED ? false : true,
      });
    } else {
      this.setState({
        loanFilters: {
          ...this.state.loanFilters,
          status: "",
          category: "",
        },
        disabled: event.target.value === DISBURSED ? false : true,
      });
    }
  };

  handleChangeCategoryFilter = (event) => {
    this.setState({
      loanFilters: {
        ...this.state.loanFilters,
        category:
          event.target.value === CATEGORY[0].label
            ? ""
            : event.target.value,
      },
    });
  };

  handleSearchLoanIdChange = (event) => {
    this.setState({
      loanFilters: {
        ...this.state.loanFilters,
        loanId: event.target.value,
      },
    });
  };

  handleDateChange = (key) => (dateMoment) => {
    let DateValue = "";
    switch (key) {
      case DATE_TYPE.FROM_DATE:
        if (dateMoment.isAfter(this.state.loanFilters.toDate)) {
          DateValue = this.state.loanFilters.fromDate;
        } else {
          dateMoment.set({ hour: 0, minute: 0, second: 0 });
          DateValue = dateMoment.format(DEFAULT_DATE_FORMAT);
        }
        break;
      case DATE_TYPE.TO_DATE:
        if (
          dateMoment.isBefore(this.state.loanFilters.fromDate) ||
          dateMoment.isAfter(new Date())
        ) {
          DateValue =
            moment(dateMoment).diff(
              this.state.loanFilters.fromDate,
              "days"
            ) === 0
              ? this.state.loanFilters.fromDate
              : this.state.loanFilters.toDate;
        } else {
          DateValue = dateMoment.format(DEFAULT_DATE_FORMAT);
        }
        break;
      default:
    }

    this.setState({
      [key]: DateValue,
      loanFilters: { ...this.state.loanFilters, [key]: DateValue },
    });
  };

  onPageChange = ({ page, pageLength }) => {
    this.setState(
      {
        activePage: page,
        recordsPerPage: pageLength,
      },
      this.handleOnClickSearchFilter
    );
  };

  handleOnClickSearchFilter = (isDefaultOffset = false) => {
    isDefaultOffset &&
      this.setState({ activePage: DEFAULT_PAGINATION_ACTIVE_PAGE });
    this.props.handleLoanSearchFilter({
      search: {
        matricesId: this.props.loanHeaderDetails.minimart.mid,
        status: this.state.loanFilters.status,
        fromDate: this.state.loanFilters.fromDate,
        loanId: this.state.loanFilters.loanId,
        category:
          this.state.loanFilters.category !== ""
            ? this.state.loanFilters.category
            : undefined,
        toDate: this.state.loanFilters.toDate,
      },
      limit: this.state.recordsPerPage,
      offset: isDefaultOffset
        ? DEFAULT_PAGINATION_ACTIVE_PAGE
        : this.state.activePage,
    });
  };

  resetSearchValues = () => {
    this.setState(
      {
        loanFilters: {
          status: DEFAULT_LOAN_STATUS.value,
          category: "",
          fromDate: moment(new Date("Jan 01 2019")).format(
            DEFAULT_DATE_FORMAT
          ),
          toDate: moment(new Date()).format(DEFAULT_DATE_FORMAT),
          loanId: this.props.loanHeaderDetails?.alid
            ? this.props.loanHeaderDetails?.alid
            : this.props.searchFilter.lid,
        },
        recordsPerPage: this.state.recordsPerPage,
        activePage: DEFAULT_PAGINATION_ACTIVE_PAGE,
      },
      this.handleOnClickSearchFilter
    );
  };

  handleDeleteEvidenceClick = (documentId) => {
    const deletedEvidence =
      this.props.loanApplicationDetails.documents.find(
        (document) => document.documentId === documentId
      );

    const index = EVIDENCE.findIndex(
      (document) => document.type === deletedEvidence.docType
    );

    let evidence;
    if (deletedEvidence.type === EVIDENCE_TYPES.LOCAL_PHOTO)
      evidence = index + 1;
    else if (deletedEvidence.type === EVIDENCE_TYPES.INVOICE)
      evidence = index + 1;
    else evidence = "";

    this.setState({
      showUserModal: true,
      deletedEvidence,
      deleteEvidenceMessage: Strings("DeleteEvidenceMessage").replace(
        "$",
        Strings(deletedEvidence.type).concat(evidence)
      ),
    });
  };

  handleDeleteEvidenceAction = () => {
    const deletedEvidence = this.state.deletedEvidence;
    this.setState({
      showUserModal: false,
      deletedEvidence: null,
      deleteEvidenceMessage: "",
    });
    if (deletedEvidence)
      this.props.handleDeleteEvidenceDocumentAction(
        this.state.deletedEvidence.documentId
      );
  };


  onClickOfAcceptNotes = () => {
    if (this.state.note) {
      this.props.updateLoanApplicationStatus(
        {
          status: this.state.loanStatusToBeUpdated,
          note: this.state.note,
          noteType: this.state.noteType,
          oc: this.state.ownerCedulaId,
          loanIdToConciliate: this.state.selectedLoansToConciliate
        },
        this.closeNotesModal,
        this.showinsufficientCreditLimitMessage,
      );
    }
  }

  onConfirmApprove = () => {
    if (this.state.note) {
      this.onClickOfAcceptNotes();
    } else {
      if ((this.state.loanStatusToBeUpdated === ANALYST_ACTION.APPROVE
        || this.state.loanStatusToBeUpdated === ANALYST_ACTION.APPROVE_WITH_CONCILIATION)
        && (this.state.note === null || this.state.note === "")) {
        this.setState({
          showUserModal: false,
          noteType: this.state.loanStatusToBeUpdated,
          notesModal: true,
        });
      } else {
        this.props.updateLoanApplicationStatus(
          {
            status: this.state.loanStatusToBeUpdated,
            note: this.state.note,
            oc: this.state.ownerCedulaId,
            loanIdToConciliate: this.state.selectedLoansToConciliate
          },
          this.closeNotesModal,
          this.showinsufficientCreditLimitMessage,
        );
      }
    }
  }

  renderNotesModal = () => {
    return (
      <NotesModal
        open={this.state.notesModal}
        modalHeader={Strings("Notes")}
        toggleModal={this.closeNotesModal}
        noteType={this.state.noteType}
        addNote={this.addNote}
        isEditNote={false}
        isNotesCompulsory={true}
        maxLength={500}
        errorMessage=''
        poi={this.props.loanHeaderDetails?.poi}
        oc={this.props.loanHeaderDetails?.minimart.oc}
        mod={this.props.loanHeaderDetails?.mod}
      />
    );
  };

  renderLoanApplicationHeader = () => {
    return (
      this.props.loanHeaderDetails && (
        <LoanApplicationHeader
          {...this.props.loanHeaderDetails}
          credoErrorMessage={this.props.credoErrorMessage}
          applicationId={this.props.applicationId}
          creditScore={this.props.creditScore}
          credoCreditScore={this.props.credoCreditScore}
          thresholdCredoCreditScore={
            this.props.thresholdCredoCreditScore
          }
          errorMessage={this.state.errorMessage}
          errorFromAPi={this.props.errorMessage}
          errorForEmptyFields={this.state.errorForEmptyFields}
          isAnalyst={this.props.isAnalyst}
          isAnalystModerator={this.props.isAnalystModerator}
          isOperationsOfficer={this.props.isOperationsOfficer}
          isOperationsModerator={this.props.isOperationsModerator}
          isOperationsManager={this.props.isOperationsManager}
          loanType={this.props.loanHeaderDetails?.info.ty}
          sendUpdatedMinimartDetails={
            this.props.sendUpdatedMinimartDetails
          }
          downloadDownPaymentRecepit={
            this.props.downloadDownPaymentRecepit
          }
          downloadCreditAgreement={this.props.downloadCreditAgreement}
          isHistory={this.props.isHistory}
          businessTypeList={this.props.businessTypeList}
          // operationsDraftedData={this.props.operationsDraftedData}
          handleConciliationInputChange={
            this.handleConciliationInputChange
          }
          submitConcilitionDetails={this.submitConcilitionDetails}
          conciliationDetails={this.state.conciliationDetails}
          isAddressSplit={this.isAddressSplit()}
          featureName={this.props.featureName}
          showSupplierInactiveWarning={this.props.loanApplicationDetails?.supplierInfo?.sd?.isActive === false}
          showBankInactiveWarning={this.props.loanApplicationDetails?.supplierInfo?.ibama === false}
          resetConciliationDetails={this.resetConciliationDetails}
        />
      )
    );
  };

  renderMinimartDetails = () => {
    return (
      this.props.loanHeaderDetails?.minimart.inm && (
        <Accordion className='container-fluid' defaultActiveKey='0'>
          <Card>
            <Accordion.Toggle
              as={Card.Header}
              variant=''
              eventKey='0'
            >
              <div
                className='collapseHeading subHeading dropdown-toggle'
                id='collapse'
              >
                {Strings("seeMinimartDetails")}
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey='0'>
              <Card.Body>
                <ApprovalMinimart
                  minimart={this.props.loanHeaderDetails?.minimart}
                  isHistory={this.props.isHistory}
                  isAnalyst={this.props.isAnalyst}
                  isOperationsOfficer={this.props.isOperationsOfficer}
                  isOperationsModerator={
                    this.props.isOperationsModerator
                  }
                  isOperationsManager={this.props.isOperationsManager}
                  isAnalystModerator={this.props.isAnalystModerator}
                  branches={this.props.branches}
                  updateMinimartDetails={
                    this.props.updateMinimartDetails
                  }
                  applicationStatus={
                    this.props.loanHeaderDetails.info.st
                  }
                  businessTypeList={this.props.businessTypeList}
                  zones={this.props.zones}
                  mZones={this.props.mZones}
                  getZonesByBranch={this.props.getZonesByBranch}
                  visitDayErrorMessage={
                    this.state.visitDayErrorMessage
                  }
                  setVisitDayRequiredError={
                    this.setVisitDayRequiredError
                  }
                  rrf={this.props.loanHeaderDetails.info.rrf}
                  mod={this.props.loanHeaderDetails?.mod}
                />
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      )
    );
  };

  renderLoanApplicationDetails = () => {
    return (
      this.props.loanApplicationDetails && (
        <Accordion className='container-fluid' defaultActiveKey='1'>
          <Card>
            <Accordion.Toggle
              as={Card.Header}
              variant=''
              eventKey='1'
            >
              <div
                className='collapseHeading subHeading dropdown-toggle'
                id='collapse'
              >
                {Strings("ViewLoanApplication")}
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey='1'>
              <Card.Body>
                <ViewLoanApplication
                  {...this.props.loanApplicationDetails}
                  viewDocument={this.props.viewDocument}
                  isAnalyst={
                    !this.props.isHistory && (this.props.isAnalyst || this.props.isAnalystModerator)
                  }
                  handleDeleteEvidenceClick={
                    this.handleDeleteEvidenceClick
                  }
                  addNote={this.props.addNote}
                  deleteNote={this.props.deleteNote}
                  editNote={this.editNote}

                  //update loan application form
                  dropdownValues={this.props.dropdownValues}
                  suppliersList={this.props.suppliersList}
                  bankDetails={this.props.bankDetails}
                  supplierDropdownValues={this.props.supplierDropdownValues}
                  updateLoanApplicationForm={this.props.updateLoanApplicationForm}
                  initiatedFrom={this.props?.loanHeaderDetails?.info.if}
                />
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      )
    );
  };

  renderLoanCards = () => {
    let LoanFilters = [
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.handleChangeStatusFilter,
        Value: this.state.loanFilters.status,
        fieldname: SEARCH_FILTER_FIELDS.STATUS,
        filterDropdownList: this.state.loanStatuses,
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        disabled: this.state.disabled,
        handleOnChange: this.handleChangeCategoryFilter,
        Value: this.state.loanFilters.category,
        fieldname: SEARCH_FILTER_FIELDS.CATEGORY,
        filterDropdownList: CATEGORY,
      },
      {
        type: SEARCH_FILTER_TYPE.DATE,
        handleOnChange: this.handleChangefromDate,
        fromDate: this.state.loanFilters.fromDate,
        handleCalenderChange: this.handleDateChange,
        toDate: this.state.loanFilters.toDate,
        fieldname: SEARCH_FILTER_FIELDS.DATE,
      },
      {
        type: SEARCH_FILTER_TYPE.INPUT,
        searchPlaceHolder: Strings("loanId"),
        handleOnChange: this.handleSearchLoanIdChange,
        Value: this.state.loanFilters.loanId,
        fieldname: SEARCH_FILTER_FIELDS.LOAN_ID,
        column: 2,
        inputType: SEARCH_FILTER_TYPE.NUMBER,
      },
    ];
    return (
      !this.props.isHistory &&
      (this.props.isAnalyst ||
        this.props.isAnalystModerator ||
        this.props.isAuditor) && (
        <Accordion className='container-fluid'>
          <Card>
            <Accordion.Toggle
              as={Card.Header}
              variant=''
              eventKey='2'
              onClick={() => {
                this.setState(
                  (prevState) => {
                    return {
                      ...prevState,
                      loanAccordionOpen: !prevState.loanAccordionOpen,
                    };
                  },
                  () => {
                    this.state.loanAccordionOpen &&
                      this.handleOnClickSearchFilter();
                  }
                );
              }}
            >
              <div
                className='collapseHeading subHeading dropdown-toggle'
                id='collapse'
              >
                {Strings("Loans")}
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey='2'>
              <Card.Body>
                <Loans
                  loanFilters={LoanFilters}
                  loans={this.props.loans}
                  recordsPerPage={this.state.recordsPerPage}
                  activePage={this.state.activePage}
                  numberOfPages={this.props.numberOfPages}
                  onPageChange={this.onPageChange}
                  handleOnClickSearchFilter={
                    this.handleOnClickSearchFilter
                  }
                  handleOnClickResetFilter={this.resetSearchValues}
                  isAnalyst={
                    this.props.isAnalystModerator ||
                    this.props.isAuditor
                  }
                  setLoanDetailsID={this.props.setLoanDetailsID}
                />
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      )
    );
  };

  onFileChange = (event) => {
    this.setState({
      transferReceipt: event.target.files[0],
      operationInputError: ""
    })
  }

  uploadTransferReciept = () => {
    let validFile = true;
    let errorMessage = "";
    let fileExtension = Utility.getFileExtension(this.state.transferReceipt);;
    if (
      !_.includes(
        FILE_CONFIGURATIONS.allowedExtensions,
        fileExtension
      )
    ) {
      validFile = false;
      errorMessage = Strings("fileExtensionErrorOfTranferReceipt")
    }
    if (validFile && this.state.transferReceipt.size > TRANSFER_RECEIPT_FILE_SIZE) {
      validFile = false;
      errorMessage = Strings("sizeIsTooLarge");
    }
    if (validFile) {
      let fileType = this.getFileType(this.state.transferReceipt);
      let request = {
        applicationId: this.props.applicationId,
        receipt: this.state.transferReceipt,
        fileType: fileType
      }
      this.props.uploadTransferReciept(request);
    } else {
      this.setState({ operationInputError: errorMessage })
    }
  }


  getFileType = (file) => {
    if (file) {
      let fileExtension = Utility.getFileExtension(file);
      if (fileExtension === FILE_TYPE.pdf.toLowerCase()) {
        return FILE_TYPE.pdf;
      } else if (fileExtension === FILE_TYPE.jpg.toLowerCase()) {
        return FILE_TYPE.jpg;
      } else if (fileExtension === FILE_TYPE.jpeg.toLowerCase()) {
        return FILE_TYPE.jpeg;
      } else if (fileExtension === FILE_TYPE.png.toLowerCase()) {
        return FILE_TYPE.png;
      }
    }

    return null;
  };

  viewReceipt = () => {
    const s3Url = Utility.getDecryptedData(this.props.loanHeaderDetails?.info?.tr)
    window.open(s3Url);
  }

  renderOperationOffInputSection = () => {
    return (
      <Accordion className='container-fluid' defaultActiveKey='4'>
        <Card className='operationCard'>
          <Accordion.Toggle as={Card.Header} variant='' eventKey='4'>
            <div
              className='collapseHeading subHeading dropdown-toggle'
              id='collapse'
            >
              {Strings("operationsInput")}
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey='4'>
            <Card.Body>
              <OperationsOfficersInput
                errorMessage={this.state.operationInputError}
                isHistory={this.props.isHistory}
                updateOfficerInput={this.props.updateOfficerInput}
                applicationId={this.props.applicationId}
                orderId={this.state.orderId}
                handleInputChange={this.handleOrderIdInputChange}

                //Transfer reciept
                isFileUpload={this.props.loanHeaderDetails?.info.ty === LOAN_TYPES.EXTERNAL_FACTORING || this.props.loanHeaderDetails?.info.ty === LOAN_TYPES.FAST_CASH}
                onFileChange={this.onFileChange}
                transferReceipt={this.state.transferReceipt}
                uploadTransferReciept={this.uploadTransferReciept}
                viewReceipt={this.viewReceipt}
                uploadedTransferReciept={this.props.loanHeaderDetails?.info?.tr}
              />
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    );
  };

  renderInputSection = () => {
    return (
      <>
        <Accordion className='container-fluid' defaultActiveKey='3'>
          <Card>
            <Accordion.Toggle
              as={Card.Header}
              variant=''
              eventKey='3'
            >
              <div
                className='collapseHeading subHeading dropdown-toggle'
                id='collapse'
              >
                {Strings("AnalystInput")}
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey='3'>
              <Card.Body>
                <AnalystInput
                  calculateCreditScore={this.calculateCreditScore}
                  addNote={this.props.addNote}
                  errorMessage={this.state.errorMessage}
                  errorFromAPi={this.props.errorMessage}
                  riskNotes={this.props.riskNotes}
                  referenceNotes={this.props.referenceNotes}
                  regulatorsNotes={this.props.regulatorsNotes}
                  deleteNote={this.props.deleteNote}
                  editNote={this.editNote}
                  resetError={this.resetError}
                  analystPrefilledData={
                    this.props.analystPrefilledData
                  }
                  isAnalyst={this.props.isAnalyst}
                  isAnalystModerator={this.props.isAnalystModerator}
                  isHistory={
                    this.props.isHistory
                  }
                  toUpdateNegotiation={this.props.toUpdateNegotiation}
                  readOnly={this.props.isAuditor}
                  isWrongLoanCombination={
                    this.props.loanHeaderDetails?.ivc
                  }
                  isMultipleActiveLoans={
                    this.props.loanHeaderDetails?.mal
                  }
                />
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </>
    );
  };

  renderNegotiationComponent = () => {
    return (
      this.props.loanHeaderDetails && <Accordion className='container-fluid' defaultActiveKey='4'>
        <Card>
          <Accordion.Toggle
            as={Card.Header}
            variant=''
            eventKey='4'
          >
            <div
              className='collapseHeading subHeading dropdown-toggle'
              id='collapse'
            >
              {Strings("Negotiation")}
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey='4'>
            <Card.Body>
              <NegotiationComponent
                ref={this.negotiationRef}
                loanType={this.props.loanHeaderDetails?.info.ty}
                draftedNegotiation={
                  this.props.draftedNegotiation
                }
                emiDuration={this.props.emiDuration}
                updateLoanNegotiationAndTerms={
                  this.updateLoanNegotiationAndTerms
                }
                updateNegotiation={this.updateNegotiation}
                getEmiDuration={this.props.getEmiDuration}
                errorForNegotiation={
                  this.state.errorForNegotiation
                }
                errorMessage={this.props.errorMessage}
                isHistory={this.props.isHistory}
                resetError={this.resetErrorForNegotiation}
                toUpdateNegotiation={
                  this.props.toUpdateNegotiation
                }
                requestedAmount={
                  this.props.loanHeaderDetails?.info["amt"]
                }
                isWrongLoanCombination={
                  this.props.loanHeaderDetails?.ivc
                }
                isMultipleActiveLoans={
                  this.props.loanHeaderDetails?.mal
                }
                applicationId={this.props.applicationId}
                loanAmount={
                  this.props.loanHeaderDetails.info.amt
                }
                isExceptional={
                  this.props.loanHeaderDetails.minimart.iex
                }
                level={
                  this.props.loanHeaderDetails.minimart.lvl
                }
                isAnalyst={this.props.isAnalyst || this.props.isAnalystModerator}

                //Change collection days
                getPossibleCollectionCycles={this.props.getPossibleCollectionCycles}
                possibleCollectionDays={this.props.possibleCollectionDays}
                currentCollectionCycle={this.props.currentCollectionCycle}
                getFreqList={this.props.getFreqList}
                getTermsArray={this.props.getTermsArray}
                termsArray={this.props.termsArray}
                freqList={this.props.freqList}
                matricesId={this.props.loanHeaderDetails.minimart.mid}
                getFrequencyList={this.props.getFrequencyList}
                getTermsAndrate={this.props.getTermsAndrate}
              />
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    );
  };

  renderNotes = (notes, user) => {
    return notes.length > 0 ? (
      <Accordion className='container-fluid' defaultActiveKey='5'>
        <Card>
          <Accordion.Toggle as={Card.Header} variant='' eventKey='5'>
            <div
              className='collapseHeading subHeading dropdown-toggle'
              id='collapse'
            >
              {Strings(`${user}Note`)}
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey='5'>
            <Card.Body>
              <div className='analystInput'>
                {notes.map((note) => {
                  return (
                    <div className='noteSection' key={note.id}>
                      <span className='subHeading'>
                        {Strings("author")}:&nbsp;{note.author}
                      </span>
                      <span className='paddingLeft subText'>
                        {moment(note.ca).format(
                          CONFIGURATION_DATE_TIME_FORMAT
                        )}
                      </span>

                      <div className='subText scrollAuto'>
                        {note.note}
                      </div>
                    </div>
                  );
                })}
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    ) : null;
  };

  isAddressSplit = () => {
    return !(
      this.props.loanHeaderDetails.minimart.ct &&
      this.props.loanHeaderDetails.minimart.scr &&
      this.props.loanHeaderDetails.minimart.srt &&
      this.props.loanHeaderDetails.minimart.no &&
      this.props.loanHeaderDetails.minimart.ref
    );
  };

  renderAnalystButtons = () => {
    return (
      this.props.loanHeaderDetails && (
        <AnalystActionButtons
          goBack={this.props.goBack}
          updateLoanApplicationStatus={
            this.props.updateLoanApplicationStatus
          }
          openNotesModal={this.toggleModal}
          isAnalyst={this.props.isAnalyst}
          isAnalystModerator={this.props.isAnalystModerator}
          isOperationsOfficer={this.props.isOperationsOfficer}
          isOperationsModerator={this.props.isOperationsModerator}
          isAccountsOfficer={this.props.isAccountsOfficer}
          isOperationsManager={this.props.isOperationsManager}
          applicationStatus={this.props.loanHeaderDetails.info.st}
          isHistory={this.props.isHistory}
          docDeleted={this.props.loanApplicationDetails.docDeleted}
          isDownpaymentCollected={
            this.props.loanHeaderDetails?.info?.dAmt !== 0 &&
            this.props.loanHeaderDetails?.info?.dAmt !== undefined &&
            this.props.loanHeaderDetails?.info?.dAmt !== null
          }
          remittanceStatus={this.props.loanHeaderDetails.info.rs}
          loanType={this.props.loanHeaderDetails?.info.ty}
          submitInput={this.toggleModalEditUserModal}
          applicationId={this.props.applicationId}
          isWrongLoanCombination={this.props.loanHeaderDetails?.ivc}
          isMultipleActiveLoans={this.props.loanHeaderDetails?.mal}
          isICL={this.props.loanHeaderDetails?.icl}
          minimart={this.props.loanHeaderDetails?.minimart}
          setVisitDayRequiredError={this.setVisitDayRequiredError}
          initiatedFrom={this.props.loanHeaderDetails?.info.if}
          loanId={this.props.loanHeaderDetails.alid}
          setOperationsInputError={this.setOperationsInputError}
          orderId={this.state.orderId}
          oldOrderId={this.props.loanHeaderDetails?.info.oid}
          isAddressSplit={this.isAddressSplit()}
          featureName={this.props.featureName}
          transferReceipt={this.props.loanHeaderDetails?.info?.tr}
          disbursementType={this.props.loanApplicationDetails?.supplierInfo?.sdt}
          operationInputErrorCheck={this.props.loanApplicationDetails?.supplierInfo?.sdt === "TRANSFER"}
          isRemittanceRequired={this.props.loanHeaderDetails?.info?.irr}
          isConciliatedLoanClosed={this.props.loanHeaderDetails?.clmd}
        />
      )
    );
  };

  setVisitDayRequiredError = (message) => {
    this.setState({ visitDayErrorMessage: message }, () => {
      this.scrollToTop();
    });
  };

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  calculateRemitanceAmount = () => {
    let Data = this.state.conciliationDetails;
    const totalTd = Data?.reduce((sum, item) => {
      const td = item.upc + item.uplf + item.upi;
      return sum + td;
    }, 0);
    const totalApamt = Data?.reduce((sum, item) => sum + item.apamt, 0);
    const atm = (Data.length > 1 ? totalApamt / Data.length : totalApamt) - totalTd;
    return atm
  }
  toggleModalEditUserModal = () => {
    let remittanceAmount = 0;
    if (
      this.props.loanHeaderDetails?.info.ty === LOAN_TYPES.CASH_TOPUP &&
      this.props.loanHeaderDetails?.cld.length > 0
    ) {
      remittanceAmount = this.calculateRemitanceAmount();
    } else if (
      ALLOWED_LOAN_TYPES_NEGOTIATION.includes(
        this.props.loanHeaderDetails?.info.ty
      ) ||
      this.props.loanHeaderDetails?.info.ty === LOAN_TYPES.FAST_CASH
    ) {
      remittanceAmount = this.props.loanHeaderDetails?.info.appAmt;
    }
    this.setState({
      showEditUserModal: !this.state.showEditUserModal,
      remittanceDeliveryAmount: remittanceAmount,
      remittanceAmount: !this.state.showEditUserModal
        ? remittanceAmount
        : this.state.remittanceAmount,
      remittanceSector: this.props.loanHeaderDetails.minimart.scr,
      remittanceStreet: this.props.loanHeaderDetails.minimart.srt,
      remittanceNumber: this.props.loanHeaderDetails.minimart.no,
      remittanceReference: this.props.loanHeaderDetails.minimart.ref,
      remittanceCity: this.props.loanHeaderDetails.minimart.ct,
      remittanceTelNumber: this.props.loanHeaderDetails.minimart.tp,
      remittanceExtraTelNumber:
        this.props.loanHeaderDetails.minimart.otp,
    });
  };

  checkRemittaceInput = () => {
    if (this.state.remittanceAmount === "") {
      this.setState({ errorMessage: Strings("PleaseEnterAmount") });
      return false;
    } else if (
      this.state.remittanceSector === null ||
      this.state.remittanceStreet === null ||
      this.state.remittanceNumber === null ||
      this.state.remittanceReference === null ||
      this.state.remittanceCity === null ||
      this.state.remittanceSector === "" ||
      this.state.remittanceStreet === "" ||
      this.state.remittanceNumber === "" ||
      this.state.remittanceReference === "" ||
      this.state.remittanceCity === ""
    ) {
      this.setState({
        errorMessage: Strings("AddressCannotBeEmpty"),
      });
      return false;
    } else if (!this.state.remittanceTelNumber) {
      this.setState({
        errorMessage: Strings("telephoneNumber"),
      });
      return false;
    } else if (
      this.state.remittanceTelNumber.length !== 10 ||
      this.state.remittanceExtraTelNumber.length !== 10
    ) {
      this.setState({
        errorMessage: Strings("telephoneNumberValidation"),
      });
      return false;
    }
    return true;
  };

  onYesClick = () => {
    let editableData = this.state.conciliationDetails;
    const cld = editableData.map((item) => {
      const td = item.upc + item.uplf + item.upi;
      return {
        aid: item.aid,
        upc: item.upc,
        upi: item.upi,
        uplf: item.uplf,
        td
      };
    });
    const totalTd = cld.reduce((sum, item) => sum + item.td, 0);
    const totalApamt = editableData.reduce((sum, item) => sum + item.apamt, 0);
    const apamt = editableData.length > 1 ? totalApamt / editableData.length : totalApamt;
    const atm = apamt - totalTd;
    const requestData = {
      cld: cld,
      apamt: apamt,
      atm: atm
    };
    if (this.checkRemittaceInput()) {
      let remittanceAmount = parseFloat(this.state.remittanceAmount);
      if (remittanceAmount < 1) {
        this.setState({ errorMessage: Strings("ZeroAmountError") });
      } else if (
        remittanceAmount <=
        parseFloat(this.props.loanHeaderDetails?.info.appAmt)
      ) {
        const remittanceAddress =
          this.state.remittanceSector +
          " " +
          this.state.remittanceStreet +
          " " +
          this.state.remittanceNumber +
          " " +
          this.state.remittanceReference +
          " " +
          this.state.remittanceCity;
        const remittanceTelNumber = this.state.remittanceTelNumber;
        const remittanceExtraTelNumber =
          this.state.remittanceExtraTelNumber;
        this.setState(
          {
            showEditUserModal: !this.state.showEditUserModal,
            remittanceDeliveryAmount:
              this.props.loanApplicationDetails.loanInfo
                .amount,
            remittanceAmount: !this.state.showEditUserModal
              ? this.props.loanApplicationDetails.loanInfo
                .amount
              : remittanceAmount,
            remittanceSector: "",
            remittanceStreet: "",
            remittanceNumber: "",
            remittanceReference: "",
            remittanceCity: "",
            remittanceTelNumber: "",
            remittanceExtraTelNumber: "",
          },
          () => {
            let deliveryMessage = Strings(
              "remittanceDeliveryMessage"
            ).replace("#", this.props.applicationId);
            let dt = new Date();
            dt.setMinutes(dt.getMinutes() + 10);
            this.props.submitInput(
              {
                dm: deliveryMessage,
                dt: dt.getTime(),
                amt: remittanceAmount,
                add: remittanceAddress,
                tp: remittanceTelNumber,
                atp: remittanceExtraTelNumber,
              },
              () => {
                this.props.loanHeaderDetails?.info.ty === LOAN_TYPES.CASH_TOPUP &&
                  this.props.loanHeaderDetails?.cld.length > 0 &&
                  this.props.saveConciliation(
                    requestData
                  );
              }
            );
          }
        );
      } else {
        this.setState({
          errorMessage: Strings("RemittaneAmountError"),
        });
      }
    }
  };
  onRemittanceInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      errorMessage: "",
    });
  };
  resetConciliationDetails = () => {
    this.setState({
      conciliationDetails: this.props.loanHeaderDetails?.cld
    })
  }
  renderEditableModal = () => {
    const atm = this.calculateRemitanceAmount()
    let editDetails = [
      {
        title: Strings("remittanceAmount"),
        value: this.state.remittanceAmount,
        isMandatoryField: true,
        name: "remittanceAmount",
        type: "number",
        thousandSeparator: true,
      },
      {
        title: Strings("Sector"),
        value: this.state.remittanceSector,
        isMandatoryField: true,
        name: "remittanceSector",
        type: "text",
      },
      {
        title: Strings("Street"),
        value: this.state.remittanceStreet,
        isMandatoryField: true,
        name: "remittanceStreet",
        type: "text",
      },
      {
        title: Strings("number"),
        value: this.state.remittanceNumber,
        isMandatoryField: true,
        name: "remittanceNumber",
        type: "text",
      },
      {
        title: Strings("Reference"),
        value: this.state.remittanceReference,
        isMandatoryField: true,
        name: "remittanceReference",
        type: "text",
      },
      {
        title: Strings("City"),
        value: this.state.remittanceCity,
        isMandatoryField: true,
        name: "remittanceCity",
        type: "text",
      },
      {
        title: Strings("remittanceTelNumber"),
        value: this.state.remittanceTelNumber,
        isMandatoryField: true,
        name: "remittanceTelNumber",
        type: "number",
      },
      {
        title: Strings("AlternateNumber"),
        value: this.state.remittanceExtraTelNumber,
        isMandatoryField: false,
        name: "remittanceExtraTelNumber",
        type: "number",
      },
    ];
    return (
      <EditableModal
        open={this.state.showEditUserModal}
        modalCloseOnEsc={false}
        modalCloseOnOverlayClick={false}
        modalShowCloseIcon={false}
        headerText={Strings("confirmRemittanceDelivery")}
        modalButtonCancel={{
          text: Strings("Cancel"),
          onClick: this.toggleModalEditUserModal,
        }}
        modalButtonOk={{
          text: Strings("Submit"),
          onClick: this.onYesClick,
        }}
        remittanceAmount={this.state.remittanceAmount}
        remittanceTelNumber={this.state.remittanceTelNumber}
        remittanceExtraTelNumber={this.state.remittanceExtraTelNumber}
        remittanceAddress={this.state.remittanceAddress}
        remittanceDeliveryAmount={this.state.remittanceDeliveryAmount}
        onChange={this.onRemittanceInputChange}
        SubmitMinimartDetails={this.onYesClick}
        errorMessage={this.state.errorMessage}
        isRemittanceDelivery={true}
        disabledRemittanceAmount={
          this.props.loanHeaderDetails?.info.ty === LOAN_TYPES.CASH_TOPUP &&
          this.props.loanHeaderDetails?.cld.length > 0
        }
        editableData={editDetails}
        remitanceAmount={(this.props.loanHeaderDetails?.info.ty === LOAN_TYPES.CASH_TOPUP &&
          this.props.loanHeaderDetails?.cld.length > 0) ? atm : this.state.remittanceAmount}
      />
    );
  };

  handleConciliationInputChange = (event) => {
    const { name, value, aid } = event.target;

    this.setState((prevState) => {
      const updatedDetails = prevState.conciliationDetails.map((item) => {
        if (item.aid === aid) {
          return {
            ...item,
            [name]: parseInt(value),
          };
        }
        return item;
      });

      return {
        conciliationDetails: updatedDetails,
        errorMessage: "",
      };
    });
  };

  submitConcilitionDetails = (handleOnCancelClick) => {
    const updatedDetails = this.state.conciliationDetails.map((item) => {
      return {
        ...item,
      };
    });
    this.setState(
      {
        conciliationDetails: updatedDetails,
        errorMessage: "",
      },
      () => {
        handleOnCancelClick(false);
      }
    );
  };

  handleOrderIdInputChange = (orderId) => {
    this.setState({
      orderId: orderId,
      operationInputError: "",
    });
  };

  setOperationsInputError = (errorMessage) => {
    this.setState({
      operationInputError: errorMessage,
    });
  };

  getStyleTableCell = () => {
    return styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#d5d5d5",
        color: theme.palette.common.black,
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
      },
    }));
  };

  selectLoanToConciliate = (loan) => {
    let selectedLoansToConciliate = [...this.state.selectedLoansToConciliate]; // Create a copy of the array
    if (selectedLoansToConciliate.includes(loan)) {
      selectedLoansToConciliate = selectedLoansToConciliate.filter(item => item !== loan); // Remove the loan if it is already selected
    } else {
      selectedLoansToConciliate.push(loan); // Add the loan if it is not selected
    }
    this.setState({
      selectedLoansToConciliate
    });
  }

  renderTableValues = (loan, key) => {
    let value = loan[key];
    if (key === "action") {
      value = loan.iec
        ? <CheckboxComponent
          onChange={() => this.selectLoanToConciliate(loan["lid"])}
          checked={this.state.selectedLoansToConciliate.includes(loan["lid"])}
          key={loan.loanId}
          label={value}
        />
        : "--"
    } else {
      value = Strings(
        value ? value : "NA"
      )
    }
    return value;
  }

  renderTableData = () => {
    //NOSONAR
    const StyledTableCell = this.getStyleTableCell();

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      "&:nth-of-type(even)": {
        backgroundColor: theme.palette.action.hover,
      },
      // hide last border
      "&:last-child td, &:last-child th": {
        border: 0,
      },
    }));
    return this.props.loansToConciliate.map(
      (loan, index) => {
        return (
          <StyledTableRow key={Math.random() + index}>
            {CONCILIATION_TABLE_HEADERS.map((row, rowIndex) => (
              <StyledTableCell
                key={rowIndex + row.key}
                className={!loan.iec
                  ? `disabledRow text-center`
                  : `text-center`}
              >
                {this.renderTableValues(loan, row.key)}
              </StyledTableCell>
            ))}
          </StyledTableRow>
        );
      }
    );
  };

  renderLoansToConciliateWith = () => {
    const StyledTableCell = this.getStyleTableCell();

    return (
      <div className='LoanAmountModal container-fluid'>
        <Modal
          open={this.state.showLoansToConciliateWith}
          classNames={{
            modal: "container-fluid customModal",
          }}
          closeOnEsc={() => this.toggleConciliationModal(true)}
          closeOnOverlayClick={false}
          showCloseIcon={false}
          center
        >
          <Row>
            <Col md={12} className='headerModal'>
              <h4>{Strings("LoansToConciliateWith")}</h4>
            </Col>
            {this.props.loansToConciliate.map((loan) => {
              return loan.cner
                ? <Col md={12} className="warningText">{`${Strings("LoanId")} ${loan.lid} : ${Strings(loan.cner)} `}</Col>
                : null;
            })}
            <Col md={12} className='modalContent pt-3'>
              {this.props.loansToConciliate &&
                this.props.loansToConciliate.length > 0 ? (
                <TableContainer
                  component={Paper}
                  className='d-flex justify-content-center'
                  sx={{ maxHeight: 440 }}
                >
                  <Table aria-label='customized table' stickyHeader>
                    <TableHead>
                      <TableRow>
                        {CONCILIATION_TABLE_HEADERS.length > 0 &&
                          CONCILIATION_TABLE_HEADERS.map((column) => (
                            <StyledTableCell
                              key={column.key}
                              className={"text-center header px-0"}
                              title={Strings(column.key)}
                            >
                              <div>
                                {Strings(column.name)}
                              </div>
                            </StyledTableCell>
                          ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.renderTableData()}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <div className='noRecordsFound'>
                  {Strings("NoRecordsFound")}
                </div>
              )}
              <hr />
            </Col>
            <Col md={12}>
              <div className='FileModalButton pull-right pb-2'>
                <Button
                  className={
                    "primaryModalButton buttonMinWidth marginCancelButton buttonBorder pull-right" +
                    this.props.modalButtonCancelStyle
                  }
                  onClick={() => this.toggleConciliationModal(true)}
                >
                  {Strings("Close")}
                </Button>
                <Button
                  className='primaryModalButton buttonBackground buttonMinWidth m-2'
                  onClick={this.onComfirmClickOfConfirmationModal}
                  disabled={this.state.selectedLoansToConciliate.length === 0}
                >
                  {Strings("Set")}
                </Button>
              </div>
            </Col>
          </Row>
        </Modal>
      </div >
    )
  }

  render() {

    let operationsInputAllowed = false;
    if (this.props.isOperationsOfficer ||
      this.props.isOperationsModerator ||
      this.props.isOperationsManager) {
      if (this.props.loanHeaderDetails?.info.ty === LOAN_TYPES.MOTORCYCLE_LOAN || this.props.loanHeaderDetails?.info.ty === LOAN_TYPES.CONSUMER || this.props.loanHeaderDetails?.info.ty === LOAN_TYPES.EXTERNAL_FACTORING || this.props.loanHeaderDetails?.info.ty === LOAN_TYPES.FAST_CASH) {
        operationsInputAllowed = true;
      }
      if (this.props.loanHeaderDetails?.info.ty === LOAN_TYPES.EXTERNAL_FACTORING) {
        operationsInputAllowed = this.props.loanApplicationDetails?.supplierInfo?.sdt === "TRANSFER";
      }
    }
    return (
      <div className='analystApproval ListContainer'>
        {this.state.showUserModal && this.renderConfirmationModal()}
        {this.state.showEditUserModal && this.renderEditableModal()}
        {this.state.notesModal && this.renderNotesModal()}
        {this.state.showLoansToConciliateWith && this.renderLoansToConciliateWith()}
        {this.renderLoanApplicationHeader()}
        {this.renderMinimartDetails()}
        {this.renderLoanApplicationDetails()}
        {this.renderLoanCards()}
        {this.props.loanHeaderDetails?.info.ty !== LOAN_TYPES.FAST_CASH &&
          this.props.loanHeaderDetails?.info.ty !== LOAN_TYPES.FACTORING &&
          (this.props.isAnalyst ||
            this.props.isAnalystModerator ||
            ((this.props.isOperationsModerator || this.props.isOperationsManager) &&
              this.props.featureName === "ANALYST_DASHBOARD") ||
            ((this.props.isOperationsOfficer ||
              this.props.isOperationsModerator ||
              this.props.isOperationsManager) &&
              this.props.loanHeaderDetails &&
              this.props.loanHeaderDetails.association.aa !==
              ANALYSED_BY_SYSTEM)) &&
          this.renderInputSection()}
        {this.renderNegotiationComponent()}
        {operationsInputAllowed &&
          this.renderOperationOffInputSection()}
        {this.props.loanHeaderDetails?.info.ty !== LOAN_TYPES.FAST_CASH &&
          this.props.loanHeaderDetails?.info.ty !== LOAN_TYPES.FACTORING &&
          this.props.approvalNotesList?.length > 0 &&
          this.renderNotes(
            this.props.approvalNotesList,
            USERS.ANALYST
          )}
        {this.props.loanHeaderDetails?.info.ty !== LOAN_TYPES.FAST_CASH &&
          this.props.loanHeaderDetails?.info.ty !== LOAN_TYPES.FACTORING &&
          this.props.messengerNotes?.length > 0 &&
          this.renderNotes(
            this.props.messengerNotes,
            USERS.MESSENGER
          )}

        {/* Commenting this code, as we are not accepting note for any other 
          loan except Fast cash and Factoring CI */}

        {/* {this.props.loanHeaderDetails?.info.ty !== LOAN_TYPES.FAST_CASH &&
          this.props.loanHeaderDetails?.info.ty !== LOAN_TYPES.FACTORING &&
          this.props.note &&
          this.renderNotes(
            [this.props.note],
            USERS.OPERATIONS_OFFICER
          )} */}

        {this.renderAnalystButtons()}
      </div>
    );
  }
}

export default AnalystApproval;
