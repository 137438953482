import moment from "moment";
import _ from "lodash";
import CryptoJS from "crypto-js";

// Constants
import {
  LOAN_APPLICATION_STATUS,
  COLORS,
  DATE_FORMAT,
  TIME_FORMAT,
  DATE_TIME_FORMAT,
  INVOICE_DATE_FORMAT,
  EMPTY_STRING,
  CONFIGURATION_MAPPING_FIELDS,
  REPORT_DATE_TIME_FORMAT,
  ENCRYTION_DECRYPTION_KEY,
  SORT_TYPE,
  SEARCH_FILTER_TYPE,
  EVIDENCE,
  DEFAULT_SALES_PERSON,
  DEFAULT_DATE_FORMAT,
  USER_ROLES,
} from "../constants/appConstants";
import { Strings } from "../resources/i18n/i18n";
import { viewDocumentAction } from "../components/ViewLoanApplication/ViewLoanApplicationAction";
import { OPERATION_STATUS } from "../config/axios.init";
import { GET_DEFAULT_SALES_MODERATOR_DROPDOWN } from "../components/GeneralReports/Constants";
import { getUsersBySearchFilterAction } from "../components/UserList/UserListAction";

//Assets

export default class Utility {
  /**
   * This function will return localization id for application status
   * @param {*} status -application status
   */
  static getLocalizationIdForApplicationStatus(status) {
    let id;
    switch (status) {
      case LOAN_APPLICATION_STATUS.approved:
        id = "approved";
        break;
      case LOAN_APPLICATION_STATUS.created:
        id = "created";
        break;
      case LOAN_APPLICATION_STATUS.inProcess:
        id = "inProcess";
        break;
      case LOAN_APPLICATION_STATUS.incomplete:
        id = "incomplete";
        break;
      case LOAN_APPLICATION_STATUS.rejected:
        id = "rejected";
        break;
      case LOAN_APPLICATION_STATUS.pending:
        id = "pending";
        break;
      case LOAN_APPLICATION_STATUS.submitted:
        id = "submitted";
        break;
      case LOAN_APPLICATION_STATUS.disbursed:
        id = "disbursed";
        break;
      default:
        id = "created";
        break;
    }
    return id;
  }

  /**
   * This function will return localization id for month
   * @param {*} month -a number
   */
  static getLocalizationIdForMonth(month) {
    let id;
    switch (month) {
      case 1:
        id = Strings("January");
        break;
      case 2:
        id = Strings("February");
        break;
      case 3:
        id = Strings("March");
        break;
      case 4:
        id = Strings("April");
        break;
      case 5:
        id = Strings("May");
        break;
      case 6:
        id = Strings("June");
        break;
      case 7:
        id = Strings("July");
        break;
      case 8:
        id = Strings("August");
        break;
      case 9:
        id = Strings("September");
        break;
      case 10:
        id = Strings("October");
        break;
      case 11:
        id = Strings("November");
        break;
      case 12:
        id = Strings("December");
        break;
      default:
        id = "";
        break;
    }
    return id;
  }

  static getSpanishStringForMonth(month) {
    let monthString;
    switch (month) {
      case 1:
        monthString = "Enero";
        break;
      case 2:
        monthString = "Febrero";
        break;
      case 3:
        monthString = "Marzo";
        break;
      case 4:
        monthString = "Abril";
        break;
      case 5:
        monthString = "Mayo";
        break;
      case 6:
        monthString = "Junio";
        break;
      case 7:
        monthString = "Julio";
        break;
      case 8:
        monthString = "Agosto";
        break;
      case 9:
        monthString = "Septiembre";
        break;
      case 10:
        monthString = "Octubre";
        break;
      case 11:
        monthString = "Noviembre";
        break;
      case 12:
        monthString = "Diciembre";
        break;
      default:
        monthString = "";
        break;
    }
    return monthString;
  }

  /**
   * This function will return color value for accepted status
   * @param {*} status -status of application
   */
  static getColourValueForApplicationStatus(status) {
    let colourValue;
    switch (status) {
      case LOAN_APPLICATION_STATUS.approved:
        colourValue = COLORS.green;
        break;
      case LOAN_APPLICATION_STATUS.created:
        colourValue = COLORS.lightGreen;
        break;
      case LOAN_APPLICATION_STATUS.inProcess:
        colourValue = COLORS.lightBlue;
        break;
      case LOAN_APPLICATION_STATUS.incomplete:
        colourValue = COLORS.orange;
        break;
      case LOAN_APPLICATION_STATUS.rejected:
        colourValue = COLORS.red;
        break;
      case LOAN_APPLICATION_STATUS.pending:
        colourValue = COLORS.violet;
        break;
      case LOAN_APPLICATION_STATUS.submitted:
        colourValue = COLORS.blue;
        break;
      case LOAN_APPLICATION_STATUS.disbursed:
        colourValue = COLORS.pink;
        break;
      default:
        colourValue = COLORS.lightGreen;
        break;
    }
    return colourValue;
  }

  /**
   *
   *
   */

  static getLocalizationIdForField(fieldValue) {
    switch (fieldValue) {
      case CONFIGURATION_MAPPING_FIELDS.birthDate:
        return "birthDate";
      case CONFIGURATION_MAPPING_FIELDS.COUNTRY:
        return "country";
      case CONFIGURATION_MAPPING_FIELDS.STAY_IN_SPAIN:
        return "stayInSpain";
      case CONFIGURATION_MAPPING_FIELDS.EMPLOYMENT_SITUATION:
        return "employmentSituation";
      case CONFIGURATION_MAPPING_FIELDS.JOB_TIME:
        return "jobTime";
      case CONFIGURATION_MAPPING_FIELDS.TOTAL_INCOME:
        return "totalIncome";
      case CONFIGURATION_MAPPING_FIELDS.PAYMENT_TOTAL_INCOME:
        return "paymentTotalIncome";
      case CONFIGURATION_MAPPING_FIELDS.PAYMENT_INCOME_MINUS_EXPENSES:
        return "paymentIncomeMinusExpenses";
      case CONFIGURATION_MAPPING_FIELDS.PAYMENT_OTHER_LOAN:
        return "PAYMENT_OTHER_LOAN";
      case CONFIGURATION_MAPPING_FIELDS.ADDRESS_VALIDATION:
        return "addressValidation";
      case CONFIGURATION_MAPPING_FIELDS.VALIDATION_BY_REFERENCE:
        return "validationByReference";
      case CONFIGURATION_MAPPING_FIELDS.REFERENCE1_VALIDATION:
        return "REFERENCE1_VALIDATION";
      case CONFIGURATION_MAPPING_FIELDS.REFERENCE2_VALIDATION:
        return "reference2Validation";
      default:
        return EMPTY_STRING;
    }
  }

  /**
   * This function will convert dateTime into simple date time format
   * @param {*} dateTime -date format returned by server
   */
  static getFormattedDateTime(dateTime) {
    let dateComponent = moment(dateTime).format(DATE_FORMAT);
    let timeComponent = moment(dateTime).format(TIME_FORMAT);
    return {
      date: dateComponent,
      time: timeComponent,
    };
  }

  /**
   * This function will convert dateTime into formatted string
   * @param {*} dateTime -date format returned by server
   */
  static getFormattedDateString(dateTime) {
    return moment(dateTime).format(DATE_TIME_FORMAT);
  }
  /**
   * This function will convert dateTime into formatted string
   * @param {*} dateTime -date format returned by server
   */
  static getReportFormattedDateString(dateTime) {
    return moment(dateTime).format(REPORT_DATE_TIME_FORMAT);
  }

  /**
   * This function will capitalize first letter of each word in a string
   */
  static toTitleCase = (phrase) => {
    return phrase
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  /**
   * This function will return current month aand year as object
   */
  static getCurrentMonthYear() {
    let date = new Date();
    let currentMonth = moment(date).get("month") + 1;
    let currentYear = moment(date).get("year");
    currentMonth =
      currentMonth < 10 ? "0" + currentMonth : currentMonth;
    return {
      currentMonth: currentMonth,
      currentYear: currentYear,
    };
  }

  static getCurrentYear() {
    let date = new Date();
    let currentYear = moment(date).get("year");
    // alert(currentYear)
    return {
      currentYear: currentYear,
    };
  }

  /**
   * This function will return month and year by substracting monthCount from date.
   * @param {*} date -date object
   * @param {*} monthCount -number of months to substract
   */
  static getPreviousMonthYear(date, monthCount) {
    let obj = moment(date).subtract(monthCount, "months");
    let previousMonth = moment(obj).get("month") + 1;
    let previousYear = moment(obj).get("year");
    previousMonth =
      previousMonth < 10 ? "0" + previousMonth : previousMonth;
    return {
      previousMonth: previousMonth,
      previousYear: previousYear,
    };
  }
  /**
   * This function will return set of month and year values (count=monthCount) before passed value of month and year.
   * @param {*} month -month value in number
   * @param {*} year -year value
   * @param {*} monthCount -Count of months in set
   */
  static getPreviousMonthsSet(month, year, monthCount) {
    let previousMonthYears = [];
    let dateObject = new Date(year, month, 1, 0, 0, 0, 0);
    for (let i = 0; i < monthCount; i++) {
      previousMonthYears.push({
        month:
          Utility.getPreviousMonthYear(dateObject, i).previousMonth -
            1 ===
            0
            ? 12
            : Utility.getPreviousMonthYear(dateObject, i)
              .previousMonth - 1,
        year: Utility.getPreviousMonthYear(dateObject, i)
          .previousYear,
      });
    }
    return previousMonthYears;
  }

  /**
   * This function will return object from data with same month and year, null if not found.
   * @param {*} data -array of json
   * @param {*} month -month value
   * @param {*} year -year value
   */
  static getObjectWithSameYearMonth(data, month, year) {
    let objectWithSameYearMonth = null;
    data.forEach((singleMonthData) => {
      if (
        singleMonthData.month === month &&
        singleMonthData.year === year
      ) {
        objectWithSameYearMonth = singleMonthData;
      }
    });
    return objectWithSameYearMonth;
  }

  /**
   *
   * @param {date to be converted in mili seconds} date
   */
  static convertDateIntoMiliSeconds(date) {
    let currentDate = moment();
    date = moment(date).valueOf();
    let difference = moment.duration(currentDate.diff(date));
    difference = difference.asDays();
    let timeDiff = difference - Math.floor(difference);
    if (timeDiff < 0) timeDiff = -timeDiff;
    timeDiff = Math.ceil(timeDiff * 86400000);
    date = date + timeDiff;

    return date.valueOf();
  }

  /** @param {convert  date into miliSeconds for Scheduler}*/
  static convertDateIntoMiliSecondsForScheduler(date) {
    date = moment(date);
    date.format();
    return date.valueOf();
  }

  /**
   *
   * @param {miliSeconds to be converted in Date} ms
   */
  static getDateFromMiliSeconds(ms) {
    var date = new Date(ms);
    date = date.toString();
    return moment(date).format(INVOICE_DATE_FORMAT);
  }

  /**
   *
   * @param {*} amountRequested -Selected amount
   * @param {*} months -Selected month
   * @param {*} repaymentCalculationInformation -Repayment information used for EMI calculation.
   */
  static getEMIDetailsForSelectedMonthAmount(
    amountRequested,
    months,
    repaymentCalculationInformation
  ) {
    let objectIndex = _.findIndex(
      repaymentCalculationInformation.loanAmount,
      function (obj) {
        return (
          obj.amount === Utility.getIntegerValue(amountRequested)
        );
      }
    );
    let selectedAmountObject =
      repaymentCalculationInformation.loanAmount[objectIndex];
    let emiValueObjectIndex = _.findIndex(
      selectedAmountObject.emiDetails,
      function (obj) {
        return obj.month === Utility.getIntegerValue(months);
      }
    );

    return selectedAmountObject.emiDetails[emiValueObjectIndex];
  }

  /**
   * Get object of min max amount based on selected loan index
   * @param {*} repaymentCalculationInformation - Repayment Calculation Information
   * @param {*} loanIndex -Selected Loan Index
   */
  static getMinMaxAmountObject = (
    repaymentCalculationInformation,
    loanIndex
  ) => {
    let objectMinMaxAmt = {};

    _.each(
      repaymentCalculationInformation.minMaxAmt,
      (minMaxAmtObject) => {
        if (minMaxAmtObject.index === loanIndex) {
          objectMinMaxAmt = {
            minAmount: minMaxAmtObject.minAmt,
            maxAmount: minMaxAmtObject.maxAmt,
            amountStepper:
              repaymentCalculationInformation.amountStepper,
          };
          return false;
        }
      }
    );

    return objectMinMaxAmt;
  };

  /**
   * This function will convert passed value to integer
   */
  static getIntegerValue(value) {
    let integerValue;
    integerValue = parseInt(value, 10);
    return integerValue;
  }

  /**
   * This function will truncate decimal values to specified digit
   */
  static getDecimalNumberUptoDigit(num, digit) {
    if (parseInt(num) === num) return num;
    //if num is int -> return
    else return Number.parseFloat(num).toFixed(digit);
  }

  //File extension
  static getFileExtension = (file) => {
    let nameOfFile = file.name;
    let extentensionIndex = nameOfFile.lastIndexOf(".");
    return nameOfFile.substr(extentensionIndex + 1).toLowerCase();
  };

  static checkIfNumber = (event) => {
    return (
      (event.keyCode >= 48 && event.keyCode <= 57) ||
      (event.keyCode >= 96 && event.keyCode <= 105) ||
      event.keyCode === 8 ||
      event.keyCode === 9
    );
  };

  static checkIfPostiveInteger = (num) => {
    if (isNaN(num)) {
      return false;
    } else {
      if (!num) {
        return false;
      }
      if (typeof num === "string") {
        num = num.replace(/^0+/, "") || "0";
        var n = Math.floor(Number(num));
        return n !== Infinity && String(n) === num && n >= 0;
      } else {
        return true;
      }
    }
  };

  static checkIfFloat = (event) => {
    return (
      (event.keyCode >= 48 && event.keyCode <= 57) ||
      (event.keyCode >= 96 && event.keyCode <= 105) ||
      event.keyCode === 8 ||
      event.keyCode === 109 ||
      event.keyCode === 173 ||
      event.keyCode === 189 ||
      event.keyCode === 190 ||
      event.keyCode === 110 ||
      event.keyCode === 9
    );
  };

  static fixedDecimalPoint = (num, decimal) => {
    if (!isNaN(num)) {
      let spiltNum = num.split(".");
      let floatNum = "";
      if (num === "") return 0;
      if (spiltNum[1] === "") return num;
      if (!spiltNum[1]) {
        return parseInt(num);
      }
      if (spiltNum[1].length <= 2) {
        floatNum = +num;
        floatNum = floatNum.toFixed(decimal);
        return parseFloat(floatNum);
      }
      floatNum = spiltNum[0] + "." + spiltNum[1].slice(0, 2);
      floatNum = +floatNum;
      return parseFloat(floatNum);
    }
    return parseFloat(num);
  };

  static checkIfPercentageValid = (percentageArray) => {
    for (let percentage of percentageArray) {
      if (percentage < 0 || percentage > 100) return false;
    }
    return true;
  };

  static checkIfRangeValid = (daysRangeArray, isFastCash) => {
    if (daysRangeArray) {
      for (let [i, dayrange] of daysRangeArray.entries()) {
        if (isFastCash && i === 0) {
          return true;
        } else if (dayrange.lower >= dayrange.upper) return false;
      }
      return true;
    }
    return false;
  };

  static checkIfRangeOverlap = (daysRangeArray) => {
    if (daysRangeArray) {
      for (let i = 0; i < daysRangeArray.length; i++) {
        let lower = daysRangeArray[i].lower;
        let upper = daysRangeArray[i].upper;
        for (let j = 0; j < daysRangeArray.length; j++) {
          if (
            i !== j &&
            ((lower <= daysRangeArray[j].upper &&
              lower >= daysRangeArray[j].lower) ||
              (upper <= daysRangeArray[j].upper &&
                upper >= daysRangeArray[j].lower))
          ) {
            return true;
          }
        }
      }
    }
    return false;
  };

  static checkIfRangeIsContinuous = (daysRangeArray) => {
    let upperRangeArray = daysRangeArray.map((range) =>
      Number(range.upper)
    );
    let validatingArray = [];
    if (daysRangeArray) {
      for (let range of daysRangeArray) {
        let upper = Number(range.upper);
        if (upper !== Math.max(...upperRangeArray)) {
          validatingArray = daysRangeArray.filter((dayRange) =>
            Utility.checkIfNumberIsInRange(upper + 1, dayRange)
          );
          if (validatingArray.length === 0) {
            return false;
          }
        }
      }
      return true;
    }
  };

  static checkIfCreditScoreRangeIsContinuous = (rangeArray) => {
    for (let j = 0; j < rangeArray.length; j++) {
      if (j + 1 !== rangeArray.length) {
        let end = rangeArray[j].end + 1;
        if (end !== rangeArray[j + 1].start) {
          return false;
        }
      }
    }
    return true;
  };
  static checkIfRangeIsContinuousForInfiniteEnd = (rangeArray) => {
    for (let j = 0; j < rangeArray.length; j++) {
      if (j + 1 !== rangeArray.length) {
        let upper = rangeArray[j].upper + 1;
        if (upper !== rangeArray[j + 1].lower) {
          return false;
        }
      }
    }
    return true;
  };

  static checkIfNumberIsInRange = (number, range) => {
    return number >= range.lower && number <= range.upper;
  };

  static checkIfContainsZero = (
    daysRangeArray,
    daysArrears,
    isFastCash = false
  ) => {
    let zeroArray = daysArrears
      ? daysRangeArray.filter((range) => Number(range.lower) === 0)
      : daysRangeArray.filter((range) =>
        isFastCash ? true : Number(range.lower) === 1
      );
    return zeroArray.length !== 0;
  };

  static checkIfDuplicateEntryInArray = (array) => {
    for (let value of array) {
      if (array.filter((ele) => value === ele).length > 1)
        return true;
    }
    return false;
  };

  static allowOnlyNumber = (event) => {
    if (!Utility.checkIfNumber(event)) {
      event.preventDefault();
    }
  };
  static allowNumberAndSingleDot = (event) => {
    let regex = new RegExp(/^\d*\.*\d*/);

    if (!regex.test(event.target.value)) {
      event.preventDefault();
    }
  };

  static allowOnlyUptoTwelve = (event) => {
    if (
      !Utility.checkIfNumber(event) &&
      parseInt(event.target.value) > 12
    ) {
      event.preventDefault();
    }
  };

  static allowOnlyUptoSixty = (event) => {
    if (
      !Utility.checkIfNumber(event) &&
      parseInt(event.target.value) > 60
    ) {
      event.preventDefault();
    }
  };
  static allowOnlyFloat = (event) => {
    if (!Utility.checkIfFloat(event)) {
      event.preventDefault();
    }
  };

  static Validators = {
    number: Utility.allowOnlyNumber,
    float: Utility.allowOnlyFloat,
    int: Utility.allowOnlyNumber,
    text: () => {
      // This is intentional
    },
    hours: Utility.allowOnlyUptoTwelve,
    minutes: Utility.allowOnlyUptoSixty,
  };

  static checkIfValidEmail = (value) => {
    let emailRegex =
      /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return emailRegex.test(value);
  };

  static checkIfValidLatitude = (value) => {
    let latitudeRegex = /^-?([0-8]?[0-9]|90)(\.[0-9]{1,6})?$/;
    return latitudeRegex.test(value);
  }

  static checkIfValidLongitude = (value) => {
    let longitudeRegex = /^-?((1?[0-7]?|[0-9]?)[0-9]|180)(\.[0-9]{1,6})?$/;
    return longitudeRegex.test(value);
  }

  static isValidName = (name) => {
    let regexForAlphabets = /^^[a-zA-Z0-9_ ]*$/;
    return regexForAlphabets.test(name);
  };

  static sortRows = (sortColumn, sortDirection, rows) => {
    //NOSONAR
    const comparer = (a, b) => {
      if (sortDirection === SORT_TYPE.ASC) {
        return a[sortColumn] > b[sortColumn] ? 1 : -1;
      } else if (sortDirection === SORT_TYPE.DESC) {
        return a[sortColumn] < b[sortColumn] ? 1 : -1;
      }
    };
    const dateComparer = (a, b) => {
      const momentA = moment(a[sortColumn]);
      const momentB = moment(b[sortColumn]);
      if (sortDirection === SORT_TYPE.ASC) {
        return momentA.isAfter(momentB) ? 1 : -1;
      } else if (sortDirection === SORT_TYPE.DESC) {
        return momentA.isBefore(momentB) ? 1 : -1;
      }
    };

    return sortDirection === "NONE"
      ? rows
      : rows.sort(
        sortColumn !== SEARCH_FILTER_TYPE.SINGLE_DATE //NOSONAR
          ? comparer
          : dateComparer
      );
  };

  static getI18nStringForDate = (dateString) => {
    let dateArray = moment(dateString).format("LL").split(" ");

    return dateArray.reduce((accumulator, currentValue) => {
      return isNaN(currentValue)
        ? [
          accumulator,
          Utility.getSpanishStringForMonth(
            Number(moment().month(currentValue).format("M"))
          ),
        ].join(" ")
        : [accumulator, currentValue].join(" ");
    });
  };

  static getCurrencyRepresentationOfAmount = (nStr) => {
    nStr += "";
    var x = nStr.split(".");
    var x1 = x[0];
    var x2 = x.length > 1 ? "." + x[1] : "";
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, "$1,$2");
    }
    return x1 + x2;
  };

  static getAllZonesInOneArray = (branchArray) => {
    let zoneArray = [];
    if (branchArray.length > 1) {
      for (let i = 0; i < branchArray.length - 1; i++) {
        zoneArray = zoneArray.concat(branchArray[i].zones);
      }
    } else {
      zoneArray = branchArray[0].zones;
    }
    return zoneArray;
  };

  static getDecryptedData = (encryptString) => {
    try {
      const parsedBase64Key = CryptoJS.enc.Base64.parse(
        ENCRYTION_DECRYPTION_KEY
      );
      const decryptedData = CryptoJS.AES.decrypt(
        encryptString,
        parsedBase64Key,
        {
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7,
        }
      );

      return decryptedData.toString(CryptoJS.enc.Utf8);
    } catch (e) {
      console.log("error:", e.message);
    }
  };

  static getFileType = (fileType) => {
    return "." + fileType.toLowerCase();
  };

  /**
   * Sort evidence document list
   * @param {*} evidenceDocumentList
   * @returns {*} sortedDocumentsList
   */
  static getSortedEvidenceList = (evidenceDocumentList) => {
    let sortedDocumentsList = [];
    EVIDENCE.forEach((evidence) => {
      const evidenceDocument = evidenceDocumentList.find(
        (document) => document.docType === evidence.type
      );
      if (evidenceDocument) {
        sortedDocumentsList.push(evidenceDocument);
      } else {
        sortedDocumentsList.push({});
      }
    });
    return sortedDocumentsList;
  };

  static getAllUserList = (users, listTitle) => {
    let userList = [];
    userList.push({
      key: 0,
      value: "",
      label: listTitle,
    });
    for (let i = 0; i < users?.length; i++) {
      if (users[i].active === true) {
        userList.push({
          key: users[i].id,
          label: users[i].firstName + " " + users[i].lastName,
          value: users[i].id,
        });
      }
    }
    return userList;
  };

  static getAllSalesPerson = (users) => {
    let salesPersons = [];
    salesPersons.push({
      key: 0,
      value: "",
      label: DEFAULT_SALES_PERSON().name,
    });
    for (let i = 0; i < users?.length; i++) {
      users[i].firstName !== undefined
        ? salesPersons.push({
          key: users[i].id,
          label: users[i].firstName + " " + users[i].lastName,
          value: users[i].firstName + " " + users[i].lastName,
        })
        : salesPersons.push({
          key: users[i].userId,
          label: users[i].name,
          value: users[i].name,
        });
    }
    return salesPersons;
  };

  /**
   * To view loan evidence documents
   * @param {*} documentId
   * @returns
   */
  static viewDocuments = (documentId) => {
    let setStateObject = {
      errorMessage: "",
      showLoadingModal: false,
    };
    viewDocumentAction(
      { documentId: documentId, temporaryLoan: false },
      (Response) => {
        if (Response.status === OPERATION_STATUS.SUCCESS) {
          if (Response.data.data.url) {
            const s3rUl = Utility.getDecryptedData(
              Response.data.data.url
            );
            window.open(s3rUl);
          }
        } else {
          setStateObject.errorMessage = Response.error.message;
        }
      }
    );
    return setStateObject;
  };

  /**
   * This function get user list
   * @param {*} searchFilterObject
   * @param {*} setUserAction
   * @param {*} getAllUserListProfilePic
   * @returns
   */
  static getUserList = (
    searchFilterObject,
    setUserAction,
    getAllUserListProfilePic = null
  ) => {
    let stateObject = {
      showLoader: false,
      errorMessage: "",
    };
    getUsersBySearchFilterAction(
      searchFilterObject,
      (response) => {
        if (response.status === OPERATION_STATUS.SUCCESS) {
          if (getAllUserListProfilePic) {
            getAllUserListProfilePic(response.data.data);
          }
          stateObject.users = response.data.data.users;
          stateObject.numberOfPages =
            response.data.data.numberOfPages;
        } else {
          stateObject.errorMessage = response.error.message;
        }
      },
      setUserAction
    );
    return stateObject;
  };

  static getSalesModeratorUsers = (users) => {
    const salesModeratorUsers = [];
    salesModeratorUsers.push({
      label: GET_DEFAULT_SALES_MODERATOR_DROPDOWN().role,
      value: GET_DEFAULT_SALES_MODERATOR_DROPDOWN().id,
    });
    for (let i = 0; i < users?.length; i++) {
      salesModeratorUsers.push({
        label: users[i].firstName + " " + users[i].lastName,
        value: users[i].id,
      });
    }

    return salesModeratorUsers;
  };

  static createBusinessTypesList = (list) => {
    let businessTypes = [
      {
        key: 0,
        value: Strings("BusinessType"),
        label: Strings("BusinessType"),
      },
    ];
    list.forEach((type, index) => {
      businessTypes.push({
        key: index + 1,
        value: Strings(type),
        label: Strings(type),
      });
    });
    return businessTypes;
  };

  static numberConverter = (number) => {
    if (Math.abs(Number(number)) >= 1.0e12) {
      return Math.abs(Number(number)) / 1.0e12 + "T";
    } else if (Math.abs(Number(number)) >= 1.0e9) {
      return Math.abs(Number(number)) / 1.0e9 + "B";
    } else if (Math.abs(Number(number)) >= 1.0e6) {
      return Math.abs(Number(number)) / 1.0e6 + "M";
    } else if (Math.abs(Number(number)) >= 1.0e3) {
      return Math.abs(Number(number)) / 1.0e3 + "K";
    } else {
      return Math.abs(Number(number));
    }
  };

  static containsSpecialChars = (str) => {
    const specialChars = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
    return specialChars.test(str);
  };

  static checkBlobTypeForFileDownload = (blob) => {
    return blob !== "application/json"
  }

  static daySelectionForSellerAssignment = (day) => {
    let days = {
      "SUNDAY": 0,
      "MONDAY": 1,
      "TUESDAY": 2,
      "WEDNESDAY": 3,
      "THURSDAY": 4,
      "FRIDAY": 5,
      "SATURDAY": 6
    }
    let date = moment(new Date()).format(DEFAULT_DATE_FORMAT);
    if (day === "Today") {
      date = moment(new Date()).format(DEFAULT_DATE_FORMAT);
    } else {
      if (moment(date).day() === days.FRIDAY) {
        //shift to monday
        date = moment().add(3, "days").format(DEFAULT_DATE_FORMAT);
      } else if (moment(date).day() === days.SATURDAY) {
        //shift to monday
        date = moment().add(2, "days").format(DEFAULT_DATE_FORMAT);
      } else {
        //shift to next day
        date = moment().add(1, "days").format(DEFAULT_DATE_FORMAT);
      }
    }
    return date;
  }

  static convertCollectionDaysToSpanish = (collectionDays) => {
    return collectionDays.map((day) => {
      return day.split(",").map((singleDay) => {
        return Strings(singleDay);
      })
    }).toString();
  }

  static convertCollectionDaysToSpanishForDropdown = (collectionDays) => {
    return collectionDays.map((day) => {
      return {
        label: day.split(",").map((singleDay) => {
          return Strings(singleDay);
        }).join(", "), value: day
      };
    });
  }

  static renderIndexSuffix = (index) => {
    let indexSuffix = "th";
    if (index === 1) {
      indexSuffix = "st";
    } else if (index === 2) {
      indexSuffix = "nd";
    }
    return indexSuffix;
  }

  static landingScreenAsPerLoggedInUserRole = (role) => {
    let landingScreen = "";
    switch (role) {
      case USER_ROLES.configurator:
        landingScreen = "/listaDeConfiguración/puntajeDeCrédito";
        break;
      case USER_ROLES.loanPortfolioManager:
      case USER_ROLES.collectionOfficer:
      case USER_ROLES.auditor:
      case USER_ROLES.collectionsManager:
      case USER_ROLES.customerService:
      case USER_ROLES.assistantSalesManager:
        landingScreen = "/carteraDePrestamos";
        break;
      case USER_ROLES.accountsOfficer:
        landingScreen = "/verificaciónDeColección";
        break;
      case USER_ROLES.analyst:
      case USER_ROLES.analystModerator:
        landingScreen = "/panelDeAprobaciónDePréstamos";
        break;
      case USER_ROLES.operationsOfficer:
      case USER_ROLES.operationsModerator:
      case USER_ROLES.operationsManager:
        landingScreen = "/tableroDeAprobaciónDePréstamosDeOperación";
        break;
      default:
        landingScreen = "/tablero";
        break;
    }
    return landingScreen;
  }

  static debounce = (func, wait) => {
    let timeout;
    let immediate = true;

    return function (...args) {
      const context = this;
      if (immediate) {
        func.apply(context, args);
        immediate = false;
      }
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        immediate = true;
      }, wait);
    };
  };
}
