import React from "react";
import moment from "moment";
import { Row, Col, Button } from "react-bootstrap";
import _ from "lodash";

//Component
import TextFieldComponent from "../CustomUIComponents/TextFieldComponent/TextFieldComponent";

//Constants
import { FREQUENCIES, WEEKLY_SIX } from "../LoanDetails/Constants.js";
import { ALLOWED_LOAN_TYPES_NEGOTIATION } from "../../constants/appConstants.js";

//Strings
import { Strings } from "../../resources/i18n/i18n";

//Utility
import Utility from "../../utils/Utility.js";

// Styles
import "./AnalystApproval.scss";

// Assets

moment.locale("es");
class NegotiationComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: props.errorMessage,
      termsArray: props.termsArray,
      freqList: props.freqList,
      negotiationData: props.draftedNegotiation,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.errorForNegotiation !== this.props.errorForNegotiation
    ) {
      this.setState({
        errorMessage: this.props.errorForNegotiation,
      });
    }
    if (!_.isEqual(prevProps.draftedNegotiation, this.props.draftedNegotiation)) {
      this.setState({
        negotiationData: this.props.draftedNegotiation
      })
    }
    if (!_.isEqual(prevProps.termsArray, this.props.termsArray)) {
      this.setState({
        termsArray: this.props.termsArray
      })
    }
    if (!_.isEqual(prevProps.freqList, this.props.freqList)) {
      this.setState({
        freqList: this.props.freqList
      })
    }
  }
  assignTermsAndrateAction = (negotiationData) => {
    const requestData = {
      loanType: this.props.loanType,
      amount: negotiationData["amount"],
      frequency: negotiationData["frequency"]
    }
    this.props.getTermsAndrate(requestData);
  }
  onChangeInput = (event) => {
    let value = event.target.value;
    let key = event.target.name;

    let negotiationData = { ...this.state.negotiationData };
    if (key === "amount") {
      negotiationData = {
        [key]: value,
        frequency: "",
        term: "",
        collectionDays: "",
      }
    } else if (key === "frequency") {
      negotiationData = {
        ...negotiationData,
        [key]: value,
        term: "",
        collectionDays: value === FREQUENCIES.WEEKLY_SIX ? WEEKLY_SIX.toString() : "",
      }
      this.assignTermsAndrateAction(negotiationData);
    } else {
      negotiationData = {
        ...negotiationData, [key]: value,
      }
    }
    this.setState(
      {
        errorMessage: "",
        negotiationData,
      },
      () => {
        if (key === 'frequency' && this.state.negotiationData.frequency !== "") {
          this.state.negotiationData.frequency !== FREQUENCIES.WEEKLY_SIX
            && this.props.getPossibleCollectionCycles({ fr: this.state.negotiationData.frequency })
          this.props.getTermsArray(this.state.negotiationData.amount, this.state.negotiationData.frequency);
        }
      }
    );
  };

  calculateEmi = () => {

    let valid = this.checkInputs();
    if (valid) {
      this.setState(
        {
          errorMessage: "",
        },
        () => {
          this.props.resetError();
          this.props.getEmiDuration({
            ...this.state.negotiationData,
            applicationId: this.props.applicationId,
          });
        }
      );
    } else {
      this.setState({
        errorMessage: Strings("pleaseEnterValidNegotiationData"),
      });
    }
  };

  checkInputs = () => {
    let valid = true;
    if (
      !this.state.negotiationData.amount ||
      !this.state.negotiationData.term ||
      !this.state.negotiationData.frequency ||
      (this.state.negotiationData.frequency !== FREQUENCIES.WEEKLY_SIX && !this.state.negotiationData.collectionDays) ||
      (this.state.negotiationData?.term === Strings("termsUnavailable")) ||
      this.state.termsArray[0].toString() === "-1"
    ) {
      valid = false;
    }
    return valid;
  };

  getNegotiationdata = () => {
    return this.state.negotiationData
  };

  renderTextField = (key, disabled) => {
    return (
      <TextFieldComponent
        type="number"
        handleChange={this.onChangeInput}
        name={key}
        value={this.state.negotiationData[key]}
        placeholderTop={key === "amount" ? Strings(key) + " ($)" : Strings(key)}
        placeholder={Strings(key)}
        placeholderTopClass='inputPlaceholderTop'
        disabled={disabled || this.props.isHistory}
        title={disabled ? Strings("notAllowedForThisLoanType") : Strings(key)}
      />
    )
  }
  handleOnSelectClick = (event) => {
    let key = event.target.name;
    if (key === "frequency" && event.target.tagName === "SELECT") {
      const request = {
        level: this.props.level,
        loanType: this.props.loanType,
        amount: this.state.negotiationData["amount"],
        minimartId: this.props.matricesId
      }
      this.props.getFrequencyList(request);
    }
  }
  renderSelectField = (key, dropdownList, disabled = false) => {
    return (
      <TextFieldComponent
        type='select'
        handleChange={this.onChangeInput}
        placeholder={Strings(key)}
        value={this.state.negotiationData[key]}
        placeholderTop={Strings(key)}
        placeholderTopClass='inputPlaceholderTop'
        name={key}
        disabled={disabled || this.props.isHistory}
        title={disabled ? Strings("notAllowedForSelectedFrequency") : Strings(key)}
        onClick={(event) => this.handleOnSelectClick(event)}
      >
        <option default value=''>
          {Strings(key)}
        </option>
        {dropdownList.map((option) => {
          return (
            <option
              key={option.value || option}
              value={option.value || option}
            >
              {Strings(option.label) || Strings(option)}
            </option>
          );
        })}
      </TextFieldComponent>
    )
  }

  renderCalculatedEMI = () => {
    return <Col md={12} className='mt-3 noPadding'>
      <Row>
        <Col md={3} className='pl-0'>
          <div className='subHeadingText pt-2'>
            {Strings("emi")}
          </div>
        </Col>
        <Col md={6}>
          <div className='subHeadingText pt-2'>
            {Strings("emiPeriod")}<span>
              {"(" +
                Strings("AssumingLoanDisbursesToday") +
                ")"}
            </span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={3} className='pl-0'>
          <div className='subText' title={Strings("emi")}>
            {this.props.emiDuration
              ? "$ " +
              Utility.getCurrencyRepresentationOfAmount(
                this.props.emiDuration.emi
              )
              : Strings("NA")}
          </div>
        </Col>
        {this.props.emiDuration ? (
          <div className='pl-3'>
            <span className='pl-0 pr-2  subText'>
              {this.props.emiDuration &&
                moment.utc(
                  this.props.emiDuration.period.start
                ).format("dddd, ll")}
            </span>
            <span className='pl-2 pr-2 subText'>
              {Strings("To")}
            </span>
            <span className='pl-2 pr-2 subText'>
              {this.props.emiDuration &&
                moment.utc(
                  this.props.emiDuration.period.end
                ).format("dddd, ll")}
            </span>

          </div>
        ) : (
          <span className='pl-3 pr-2 subText'>
            {Strings("NA")}
          </span>
        )}
      </Row>
    </Col>
  }
  render() {

    return (
      <div>
        {this.state.errorMessage &&
          <div className='errors'>
            {this.state.errorMessage}
          </div>
        }
        <div className='subHeadingText pb-3'>
          {Strings("EMICalculation")}
        </div>
        {this.props.currentCollectionCycle && <div className="warningText mb-4">
          {`${Strings("currentCollectionCycle")} : ${Utility.convertCollectionDaysToSpanish(this.props.currentCollectionCycle.split(","))}`}
        </div>}
        <Row className="mt-2">
          <Col md={3} className='pl-0'>
            {this.renderTextField("amount",
              (this.props.isAnalyst
                && !ALLOWED_LOAN_TYPES_NEGOTIATION.includes(this.props.loanType))
              || !this.props.isAnalyst)}

          </Col>

          <Col md={3}>
            {this.renderSelectField("frequency", this.state.freqList)}
          </Col>

          <Col md={3}>
            {this.state.termsArray[0]?.toString() === "-1"
              ? this.renderSelectField("term", ["termsUnavailable"])
              : this.renderSelectField("term", this.state.termsArray)}
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={3} className="pl-0 pt-2">
            {this.props.possibleCollectionDays
              && this.renderSelectField("collectionDays",
                this.props.possibleCollectionDays,
                this.state.negotiationData.frequency === FREQUENCIES.WEEKLY_SIX)}
          </Col>

          <Col md={3} className="pt-2">
            {!this.props.isHistory && (
              <Button
                className='buttonBackground m-0'
                onClick={this.calculateEmi}
                disabled={
                  this.props.isWrongLoanCombination ||
                  this.props.isMultipleActiveLoans
                }
              >
                {Strings("calculateEmi")}
              </Button>
            )}
          </Col>

          {this.renderCalculatedEMI()}
        </Row>
        <hr />

        <div className='subHeadingText'>
          {Strings("suggestedBiggestLoanAmount")}
        </div>
        <div className='subText'>
          {this.props.draftedNegotiation
            ? "$ " +
            Utility.getCurrencyRepresentationOfAmount(
              this.props.draftedNegotiation.suggestedAmount
            )
            : Strings("NA")}
        </div>
        <hr />
        <Row>
          <Col md={3} className='pl-0'>
            {!this.props.isHistory && (
              <Button
                className='buttonBackground'
                onClick={() => this.props.updateNegotiation()}
                disabled={
                  this.props.isWrongLoanCombination ||
                  this.props.isMultipleActiveLoans
                }
              >
                {Strings("submitNegotiation")}
              </Button>
            )}
          </Col>
        </Row>
      </div >
    );
  }
}

export default NegotiationComponent;
