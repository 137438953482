import React from "react";
import { Row, Col } from "react-bootstrap";
import moment from "moment";
//Component
import EditableModal from "../LoanDetails/EditableModal";

//Action

//Constants
import {
  INVOICE_DATE_FORMAT,
  MINIMART_DETAILS_KEYS,
  LOAN_TYPE_KEYS,
  MINIMART_STATUS,
} from "../../constants/appConstants";
import {
  PROMOTION_DETAILS_KEYS,
} from "../LoanDetails/Constants";
import {
  LOAN_DETAILS_KEYS,
  CREDIT_SCORE_COLOR,
  LOAN_TYPES,
  LOAN_TYPE_LABEL,
  CONCILIATION_DETAILS_KEY,
} from "./Constants";

//Strings
import { Strings } from "../../resources/i18n/i18n";

//Utility
import Utility from "../../utils/Utility";

//Styles
import "./AnalystApproval.scss";

//Assets
import loanImage from "../../resources/images/ic_loan_portfolio_details.svg";
import editNote from "../../resources/images/ic_edit.svg";
import whatsapp_icon from "../../resources/images/whatsapp_icon.svg";

class LoanApplicationHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: props.errorMessage,
      showModal: false,
      phone: "",
      mobileNo: "",
      minimartName: "",
      ownerName: "",
      businessType: "",
      city: "",
      sector: "",
      street: "",
      reference: "",
      number: "",
      isMinimartEdit: false,
      isConciliationEdit: false,
      isWhatsAppNo: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.errorMessage !== prevProps.errorMessage) {
      this.setState({ errorMessage: this.props.errorMessage });
    }
  }

  showEditableFields = () => {
    let minimartData = this.props.minimart;
    this.setState((prevState) => ({
      showModal: !prevState.showModal,
      phone: minimartData.tp ? minimartData.tp : "",
      mobileNo: minimartData.otp ? minimartData.otp : "",
      minimartName: minimartData.mn ? minimartData.mn : "",
      ownerName: minimartData.on ? minimartData.on : "",
      businessType: minimartData.bty ? minimartData.bty : "",
      city: minimartData.ct ? minimartData.ct : "",
      sector: minimartData.scr ? minimartData.scr : "",
      street: minimartData.srt ? minimartData.srt : "",
      number: minimartData.no ? minimartData.no : "",
      reference: minimartData.ref ? minimartData.ref : "",
      isWhatsAppNo: minimartData.wnr,
      errorMessage: "",
      isMinimartEdit: true,
      isConciliationEdit: false,
    }));
  };

  showConciliationEditFields = () => {
    this.setState({
      isMinimartEdit: false,
      showModal: !this.state.showModal,
      isConciliationEdit: true,
      errorMessage: "",
    });
  };

  handleInputChange = (event) => {
    let value;
    if (
      event.target.name === "reference" ||
      event.target.name === "sector" ||
      event.target.name === "city" ||
      event.target.name === "street"
    ) {
      value = event.target.value.trimStart();
    } else if (event.target.value === Strings("BusinessType")) {
      value = "";
    } else {
      value = event.target.value;
    }
    this.setState({
      [event.target.name]:
        event.target.name === "minimartName"
          ? value.toUpperCase()
          : value,
      errorMessage: "",
    });
  };

  checkValid = () => {
    return (
      this.state.phone &&
      this.state.phone.length === 10 &&
      this.state.mobileNo &&
      this.state.mobileNo.length === 10 &&
      this.state.minimartName !== "" &&
      this.state.businessType !== "" &&
      this.state.ownerName !== "" &&
      this.state.city !== null &&
      this.state.sector !== null &&
      this.state.street !== null &&
      this.state.number !== null &&
      this.state.reference !== null &&
      this.state.city !== "" &&
      this.state.sector !== "" &&
      this.state.street !== "" &&
      this.state.number !== "" &&
      this.state.reference !== ""
    );
  };

  SubmitMinimartDetails = () => {
    if (this.checkValid()) {
      this.props.sendUpdatedMinimartDetails(
        {
          mid: this.props.minimart.mid,
          applicationId: this.props.applicationId,
          tp: this.state.phone,
          otp: this.state.mobileNo,
          mn: this.state.minimartName,
          bty: this.state.businessType,
          on: this.state.ownerName,
          wnr: this.state.isWhatsAppNo,
          ct: this.state.city,
          scr: this.state.sector,
          srt: this.state.street,
          no: this.state.number,
          ref: this.state.reference,
        },
        this.handleOnCancelClick
      );
    } else {
      if (
        this.state.phone === "" ||
        this.state.phone?.length === 0 ||
        this.state.mobileNo === "" ||
        this.state.mobileNo?.length === 0 ||
        this.state.minimartName === "" ||
        this.state.businessType === "" ||
        this.state.ownerName === "" ||
        this.state.city === "" ||
        this.state.sector === "" ||
        this.state.street === "" ||
        this.state.reference === ""
      ) {
        this.setState({
          errorMessage: Strings("pleaseEnterRequiredFields"),
        });
      }
      if (this.state.phone && this.state.phone.length !== 10) {
        this.setState({
          errorMessage: Strings("telephoneNumberValidation"),
        });
      }
      if (this.state.mobileNo && this.state.mobileNo.length !== 10) {
        this.setState({
          errorMessage: Strings("mobileNumberValidation"),
        });
      }
    }
  };
  checkValidUpi = () => {
    const cldMap = new Map(this.props.cld.map(item => [item.aid, item]));
    const isValid = this.props.conciliationDetails.every((conciliationItem) => {
      const cldItem = cldMap.get(conciliationItem.aid);
      if (cldItem) {
        return conciliationItem.upi >= 0 && conciliationItem.upi <= cldItem.upi;
      } else {
        return false;
      }
    });
    return isValid;
  }
  submitConcilitionDetails = () => {
    if (this.checkValidUpi()) {
      this.props.submitConcilitionDetails(this.handleOnCancelClick);
    } else {
      this.setState({
        errorMessage: Strings("validUnpaidInterestError"),
      });
    }
  };


  handleOnCancelClick = (isResetConciliationDetails = false) => {
    const summedCLD = this.sumCLDValues(this.props.conciliationDetails);
    let errorMessage = this.state.errorMessage;
    this.setState(
      { showModal: !this.state.showModal, errorMessage: "" },
      () => {
        if (!this.state.isMinimartEdit && errorMessage) {
          this.props.handleConciliationInputChange({
            target: { name: "upi", value: summedCLD.upi },
          });
        }
      }
    );
    isResetConciliationDetails && this.props.resetConciliationDetails()
  };

  handleConciliationInputChange = (event) => {
    if (event.target.value) {
      this.setState({ errorMessage: "" });
    }
    this.props.handleConciliationInputChange(event);
  };
  renderEditableModal = () => {
    let editDetails = [];
    if (this.state.isMinimartEdit) {
      editDetails = [
        {
          title: Strings("MiniMarketName"),
          value: this.state.minimartName,
          isMandatoryField: true,
          name: "minimartName",
          type: "text",
          md: 12,
        },
        {
          title: Strings("BusinessType"),
          value: this.state.businessType,
          isMandatoryField: true,
          name: "businessType",
          dropDownList: this.props.businessTypeList,
          type: "select",
          isSelection: true,
        },
        {
          title: Strings("ownerName"),
          value: this.state.ownerName,
          isMandatoryField: true,
          name: "ownerName",
          type: "text",
        },
        {
          title: Strings("Sector"),
          value: this.state.sector,
          isMandatoryField: true,
          name: "sector",
          type: "text",
        },
        {
          title: Strings("Street"),
          value: this.state.street,
          isMandatoryField: true,
          name: "street",
          type: "text",
        },
        {
          title: Strings("number"),
          value: this.state.number,
          isMandatoryField: true,
          name: "number",
          type: "text",
        },
        {
          title: Strings("Reference"),
          value: this.state.reference,
          isMandatoryField: true,
          name: "reference",
          type: "text",
        },
        {
          title: Strings("City"),
          value: this.state.city,
          isMandatoryField: true,
          name: "city",
          type: "text",
        },
        {
          title: Strings("PhoneNumber"),
          value: this.state.phone,
          isMandatoryField: true,
          name: "phone",
          type: "number",
        },
        {
          title: Strings("MobileNumberOfOwner"),
          value: this.state.mobileNo,
          isMandatoryField: true,
          name: "mobileNo",
          type: "number",
        },
      ]
    } else if (this.state.isConciliationEdit) {
      editDetails = this.props.conciliationDetails;
    }

    return (
      <EditableModal
        open={this.state.showModal}
        modalCloseOnEsc={false}
        modalCloseOnOverlayClick={false}
        modalShowCloseIcon={false}
        headerText={Strings(
          this.state.isMinimartEdit
            ? "EditMinimartDetails"
            : "editConciliationDeatails"
        )}
        modalButtonCancel={{
          text: Strings("Cancel"),
          onClick: this.handleOnCancelClick,
        }}
        modalButtonOk={{
          text: Strings("Submit"),
          onClick: this.state.isMinimartEdit
            ? this.SubmitMinimartDetails
            : this.submitConcilitionDetails,
        }}
        onChange={
          this.state.isMinimartEdit
            ? this.handleInputChange
            : this.handleConciliationInputChange
        }
        SubmitMinimartDetails={
          this.state.isMinimartEdit
            ? this.SubmitMinimartDetails
            : this.submitConcilitionDetails
        }
        errorMessage={this.state.errorMessage}
        editableData={editDetails}
        isWhatsAppNo={this.state.isWhatsAppNo}
        isConciliationEdit={this.state.isConciliationEdit}
        className={"conciliationDetailsModal"}
        resetConciliationDetails={(reset) => this.props.resetConciliationDetails(reset)}
      />
    );
  };
  renderLoanApplicationAnalystHeaderInfo = () => {
    return (
      <>
        {(this.props.isAnalyst || this.props.isOperationsModerator || this.props.isOperationsManager) &&
          this.props.featureName === "ANALYST_DASHBOARD" && (
            <div
              title={Strings("creditScore")}
              className='textAlignCenter'
            >
              <span className='creditScoreHeaderText'>
                {Strings("creditScore")}
              </span>
            </div>
          )}
        {(this.props.isAnalyst
          || this.props.isAnalystModerator
          || this.props.isOperationsModerator
          || this.props.isOperationsManager) &&
          this.props.featureName === "ANALYST_DASHBOARD" && (
            <div className='text-center'>
              {this.props.creditScore.cs ? (
                <div
                  className='displayBlock mainNumberText'
                  style={{
                    color:
                      CREDIT_SCORE_COLOR[this.props.creditScore.cst],
                  }}
                  title={
                    this.props.creditScore.cst &&
                    Strings(this.props.creditScore.cst.toLowerCase())
                  }
                >
                  {parseFloat(this.props.creditScore.cs)}
                </div>
              ) : null}
              {this.props.info.if === "TPRESTA" &&
                this.props.info.icr ? (
                <div
                  style={{
                    color: CREDIT_SCORE_COLOR["HIGH"],
                  }}
                  title={Strings("credoCreditScore")}
                >
                  {parseFloat(this.props.credoCreditScore)}
                </div>
              ) : null}
            </div>
          )}
      </>
    );
  };

  onReceiptClick = (recepitId) => {
    this.props.downloadDownPaymentRecepit(
      recepitId,
      this.props.applicationId
    );
  };

  renderLoanDetails = (loan) => {
    //NOSONAR
    let percentage;
    let loanInfo = this.props.info[loan.key];
    if (
      loan.label === LOAN_TYPE_LABEL.AMOUNT_REQUESTED ||
      loan.label === LOAN_TYPE_LABEL.BALANCE
    ) {
      if (this.props.info[loan.key] !== null) {
        loanInfo = Utility.getCurrencyRepresentationOfAmount(
          this.props.info[loan.key]
        );
      } else {
        loanInfo = "0";
      }
    } else if (
      loan.label === LOAN_TYPE_LABEL.DISBURSE_DATE ||
      loan.label === LOAN_TYPE_LABEL.CREATION_DATE ||
      loan.label === LOAN_TYPE_LABEL.EXPIRY_DATE
    ) {
      if (this.props.info[loan.key] !== null) {
        loanInfo = moment(this.props.info[loan.key]).format(
          INVOICE_DATE_FORMAT
        );
      } else {
        loanInfo = Strings("NotDisbursed");
      }
    } else if (loan.label === LOAN_TYPE_LABEL.DAYS_IN_ARREARS) {
      loanInfo = this.props.info[loan.key];
    } else if (
      loan.label === LOAN_TYPE_LABEL.STATUS ||
      loan.label === LOAN_TYPE_LABEL.TYPE ||
      loan.label === LOAN_TYPE_LABEL.REMITTANCE_STATUS
    ) {
      loanInfo = Strings(this.props.info[loan.key]);
    } else if (
      loan.key === LOAN_TYPE_KEYS.AMT ||
      loan.key === LOAN_TYPE_KEYS.EMI ||
      loan.key === LOAN_TYPE_KEYS.APP_AMT ||
      loan.key === LOAN_TYPE_KEYS.PC
    ) {
      percentage =
        (this.props.info[LOAN_TYPE_KEYS.DAMT] /
          this.props.info[LOAN_TYPE_KEYS.PC]) *
        100;
      loanInfo = Utility.getCurrencyRepresentationOfAmount(
        this.props.info[loan.key]
      );
    } else if (loan.key === LOAN_TYPE_KEYS.DAMT) {
      loanInfo = `${Utility.getCurrencyRepresentationOfAmount(
        this.props.info[LOAN_TYPE_KEYS.DAMT]
      )} /${Utility.getCurrencyRepresentationOfAmount(
        this.props.info[LOAN_TYPE_KEYS.EDAMT]
      )}`;
    } else if (loan.key === LOAN_TYPE_KEYS.requestedCollectionDays
      || loan.key === LOAN_TYPE_KEYS.approvedCollectionDays) {
      loanInfo = this.props.info[loan.key]
        ? Utility.convertCollectionDaysToSpanish(this.props.info[loan.key].split(","))
        : Strings("NA");
    }
    let onClickEvent = () => {
      //This is intensional
    };
    if (loan.key === LOAN_TYPE_KEYS.SCA) {
      onClickEvent = () => {
        this.props.downloadCreditAgreement(loanInfo);
      };
    } else if (loan.key === LOAN_TYPE_KEYS.DPR) {
      onClickEvent = () => this.onReceiptClick(loanInfo);
    }
    return loan.key !== LOAN_TYPE_KEYS.DR ? (
      <span
        className={
          loan.key === LOAN_TYPE_KEYS.DPR //NOSONAR
            ? "url cursorPointer"
            : "detailText"
        }
        onClick={onClickEvent}
      >
        {loanInfo ? Strings(loanInfo) : Strings("NA")}
        {percentage !== 0 && loan.key === LOAN_TYPE_KEYS.PC && (
          <span
            className={
              percentage !== 15 ? "text-danger" : "text-success" //NOSONAR
            }
          >{` (${Utility.fixedDecimalPoint(
            percentage.toString(),
            2
          )}% ${Strings("collectedDownpayment")})`}</span>
        )}
      </span>
    ) : (
      <a
        className={"url cursorPointer"}
        href={Utility.getDecryptedData(
          this.props.info[LOAN_TYPE_KEYS.DR]
        )}
        target='_blank'
        rel='noopener noreferrer'
      >
        {Strings("Devilery Receipt")}
      </a>
    );
  };

  renderLoanApplicationInfo = () => {
    let userNameLabel = this.props.isOperationsModerator || this.props.isOperationsManager
      ? Strings("operationOfficerName")
      : Strings("analystName");
    return (
      <>
        {this.props.info !== null &&
          LOAN_DETAILS_KEYS[this.props.loanType].map(
            (loan) => {
              return (
                Object.keys(this.props.info).includes(loan.key) && (
                  <Col
                    md={6}
                    className='d-flex contentWidth'
                    key={this.props.loanType + loan.key}
                  >
                    <span className='titleText '>
                      {loan.key === LOAN_TYPE_KEYS.DAMT
                        ? `${Strings("collectedDownpayment") +
                        "/" +
                        Strings("expectedDownpayment")
                        }`
                        : loan.key === LOAN_TYPE_KEYS.USER_NAME //NOSONAR
                          ? userNameLabel
                          : Strings(loan.label)}
                      &nbsp;:&nbsp;
                    </span>

                    {this.renderLoanDetails(loan)}
                  </Col>
                )
              );
            }
          )}
      </>
    );
  };

  renderMinimartDetails = () => {
    return (
      <>
        {this.props.minimart !== null &&
          MINIMART_DETAILS_KEYS.map((minimart) => {
            let renderValue = "";
            if (
              this.props.minimart[minimart.key] === "" ||
              this.props.minimart[minimart.key] === undefined ||
              this.props.minimart[minimart.key] === null
            ) {
              renderValue = Strings("NA");
            } else {
              renderValue = this.props.minimart[minimart.key];
            }
            return (
              <div
                className='d-flex contentWidth col-lg-6'
                key={minimart.key}
              >
                <span className='titleText'>
                  {Strings(minimart.label)}&nbsp;:&nbsp;
                </span>

                <span className={`detailText ${minimart.className}`}>
                  {Strings(renderValue)}
                </span>
                {minimart.key === "otp" &&
                  this.props.minimart.wnr && (
                    <img
                      className='pl-2'
                      src={whatsapp_icon}
                      alt='whatsapp_icon'
                    />
                  )}
              </div>
            );
          })}
      </>
    );
  };

  sumCLDValues = (cld) => {
    const total = cld?.reduce((acc, curr) => {
      acc.upc += curr.upc;
      acc.upi += curr.upi;
      acc.uplf += curr.uplf;
      acc.apamt += curr.apamt;
      return acc;
    }, { upc: 0, upi: 0, uplf: 0, apamt: 0 });
    total.apamt = cld.length > 0 ? total.apamt / cld.length : 0;

    return total;
  }

  renderConciliationDetails = () => {
    const summedCLD = this.sumCLDValues(this.props.conciliationDetails);
    const conciliationDetails = {
      upc: summedCLD.upc,
      upi: summedCLD.upi,
      uplf: summedCLD.uplf,
      apamt: summedCLD.apamt,
      td: summedCLD.upc + summedCLD.upi + summedCLD.uplf,
      atm: summedCLD.apamt - (summedCLD.upc + summedCLD.upi + summedCLD.uplf),
    }
    return CONCILIATION_DETAILS_KEY.map((detail) => (
      <div className='d-flex contentWidth col-lg-6' key={detail.key}>
        <span className='titleText'>
          {Strings(detail.label)}&nbsp;:&nbsp;
        </span>

        <span className={`detailText ${detail.className}`}>
          {Utility.getCurrencyRepresentationOfAmount(
            conciliationDetails[detail.key]
          )}
        </span>
      </div>
    ));
  };

  renderPromotions = () => {
    return this.props.lprd.map((promotion, index) => {
      return (
        <>
          <Row className='pt-3 underlineText w-100'>
            <Col md={12} className='pl-0'>
              {Strings("Promotions") + " #" + (index + 1)}
            </Col>
          </Row>
          <Row className='w-100'>
            {PROMOTION_DETAILS_KEYS.map((dataKey) => {
              return (
                <Col
                  md={6}
                  className='alignVertical pl-0'
                  key={dataKey.key}
                >
                  {dataKey.formmater(dataKey, promotion)}
                </Col>
              );
            })}
          </Row>
        </>
      );
    });
  };

  renderLoanApplicationHeaderInfo = () => {
    return (
      <Row>
        <Col md={1} className='noPadding'>
          <div className='justify-center display-flex'>
            <img src={loanImage} alt='loan_details_icon' />
          </div>
          <div
            title={Strings("applicationId")}
            className='justify-center display-flex'
          >
            <span className='displayBlock detailText subHeadingText mainNumberText'>
              {this.props.applicationId}
            </span>
          </div>
          {this.renderLoanApplicationAnalystHeaderInfo()}
        </Col>
        <Col
          md={11}
          className={`justify-center pl-5 ${this.props.minimart.inm ? " my-4" : " d-flex"
            }`}
        >
          <Row>
            {this.props.me && (
              <>
                <Col md={12} className='px-0 subHeadingText'>
                  {Strings("minimartOwnerVerification")}
                </Col>
                {this.props.me?.map((reason) => {
                  return (
                    <Col md={12} className='warningText pl-0'>
                      {Strings(reason.msg)}
                    </Col>
                  );
                })}
              </>
            )}
            {this.props.inmm && (
              <Col md={12} className='warningText pl-0'>
                {Strings("nameMismatchWarning")}
              </Col>
            )}

            <Col md={12} className='px-0 pt-2 subHeadingText'>
              {Strings("Info")}
            </Col>
            <Col md={12} className='d-flex px-0' key={1}>
              <Row>{this.renderLoanApplicationInfo()}</Row>
            </Col>
            {!this.props.minimart.inm ? (
              <>
                <Row className='pt-2 subHeadingText d-flex align-items-center'>
                  {Strings("Minimart")}
                  {!this.props.isHistory &&
                    (this.props.isAnalyst ||
                      this.props.isAnalystModerator ||
                      this.props.isOperationsOfficer ||
                      this.props.isOperationsModerator || this.props.isOperationsManager) && (
                      <div className={"pencil mx-1 p-1"}>
                        <img
                          src={editNote}
                          className={"editIcon m-0"}
                          alt={"edit"}
                          title={Strings("EditableFileds")}
                          onClick={this.showEditableFields}
                        />
                      </div>
                    )}
                </Row>
                <Row className='d-flex'>
                  {this.renderMinimartDetails()}
                </Row>
              </>
            ) : null}
            {(this.props.loanType === LOAN_TYPES.CASH_TOPUP &&
              this.props.cld?.length > 0) ? (
              <>
                <Row className='pt-2 subHeadingText d-flex align-items-center'>
                  {Strings("conciliationDeatails")}
                  <div className={"pencil mx-1 p-1"}>
                    <img
                      src={editNote}
                      className={"editIcon m-0"}
                      alt={"edit"}
                      title={Strings("EditableFileds")}
                      onClick={this.showConciliationEditFields}
                    />
                  </div>
                </Row>
                <Row className='d-flex'>
                  {this.renderConciliationDetails()}
                </Row>
              </>
            ) : (null)}
            {this.props.lprd &&
              this.props.lprd.length > 0 &&
              this.renderPromotions()}
          </Row>
        </Col>
      </Row>
    );
  };

  renderMOD = () => {
    let message = "";

    this.props.mod.forEach(
      (miniamrt, index) =>
      (message = `${message} 
        ${miniamrt.mn}
        (${miniamrt.mid})${index === this.props.mod.length - 1 ? "" : ","
        }`)
    );
    return (
      <div className='warningText'>
        {Strings("cedulaMODWarning")
          .replace("$", this.props.minimart.oc)
          .replace("#", message)}
      </div>
    );
  };

  render() {
    // NOSONAR
    return (
      <div className='loanApplicationHeader container-fluid'>
        {this.state.showModal && this.renderEditableModal()}
        <Row className='pt-3'>
          <Col md={2} className='subHeading bold px-0'>
            {Strings("loanDetails")}
          </Col>
          <Col md={10} className='px-0'>
            {this.props.info.if === "TPRESTA" &&
              this.props.info.icr &&
              this.props.credoCreditScore <
              this.props.thresholdCredoCreditScore ? (
              <div className='warningText'>
                {Strings("lowCredoCreditScoreWarning")}
              </div>
            ) : null}
            {this.props.credoErrorMessage ? (
              <div className='warningText'>
                {this.props.credoErrorMessage}
              </div>
            ) : null}
            {this.props.minimart !== null &&
              this.props.minimart.st === MINIMART_STATUS.BLOCKED ? (
              <div className='warningText'>
                {Strings("blockedMinimartWarning")}
              </div>
            ) : null}
            {this.props.minimart?.iob && (
              <div className='warningText'>
                {Strings("OWNER_OF_MINIMART_IS_BLACKLISTED")}
              </div>
            )}
            {this.props.info !== null &&
              this.props.info.vgl === false ? (
              <div className='warningText'>
                {Strings("WarningMinimartLocationMismatch")}
              </div>
            ) : null}
            {this.props.info.ty === LOAN_TYPES.CASH_TOPUP &&
              this.props.creditScore.cs <
              this.props.creditScore.ecs && (
                <div className='warningText'>
                  {Strings(
                    "LoanRejectionWarningBecauseOfLowCreditScore"
                  )}
                </div>
              )}
            {this.props.info.ty === LOAN_TYPES.CONSUMER &&
              this.props.creditScore.cs <
              this.props.creditScore.ecs && (
                <div className='warningText'>
                  {Strings(
                    "LoanRejectionWarningBecauseOfLowCreditScore"
                  )}
                </div>
              )}
            {this.props.clmd?.some(item => item.iclc) && (
              <div className='warningText'>
                {Strings("isConciliatedLoanClosed").replace(
                  "$",
                  this.props.clmd
                    .filter(item => item.iclc)
                    .map(item => item.alid)
                    .join(", ")
                )}
              </div>
            )}
            {this.props.isAnalyst && this.props.oae && (
              <div className='warningText'>
                {Strings("totalOutstandingError")
                  .replace(
                    "#",
                    this.props.oae.lid?.length > 1 ? "prestamos" : "prestamo"
                  )
                  .replace("#", this.props.oae.lid.join(","))
                  .replace(
                    "#",
                    "$" +
                    Utility.getCurrencyRepresentationOfAmount(
                      this.props.oae.oa
                    )
                  )}
              </div>
            )}
            {this.props.ivc && (
              <div className='warningText'>
                {Strings("wrongLoanCombinationError") +
                  this.props.ivc}
              </div>
            )}
            {this.props.mal && (
              <div className='warningText'>
                {Strings("multipleActiveLoansError") + this.props.mal}
              </div>
            )}
            {this.props.icl && (
              <div className='warningText'>
                {Strings("ICL_opr_off_msg") + this.props.icl}
              </div>
            )}
            {this.props.rrf && (
              <div className='warningText'>
                {Strings("remittanceError")}
              </div>
            )}
            {this.props.loanType === LOAN_TYPES.CASH_TOPUP &&
              this.props.clmd && (

                <div className='warningText'>
                  {Strings("conciliationWarning").replace(
                    "$",
                    this.props.clmd.map(item => item.alid).join(", ")
                  )}
                </div>
              )}
            {this.props.isAnalyst && this.props.poi && (
              <div className='warningText'>
                {Strings("cedulaWarning")
                  .replace("$", this.props.minimart.oc)
                  .replace("#", this.props.poi.join(","))}
              </div>
            )}
            {this.props.showSupplierInactiveWarning && <div className='warningText'>{Strings("supplierInactiveWarning")}</div>}
            {this.props.showBankInactiveWarning && <div className='warningText'>{Strings("bankInactiveWarning")}</div>}

            {this.props.isAnalyst &&
              this.props.mod &&
              this.renderMOD()}
            {this.props.rre && (
              <div className='errors'>
                {Strings("REMITTANCE_RAISED_ERROR") + this.props.rre}
              </div>
            )}
            {this.props.isAddressSplit && (
              <div className='errors'>
                {Strings("MINIMART_SPLIT_ADD_ERR")}
              </div>
            )}
          </Col>
          {this.props.errorFromAPi.split("\n").map((error, index) => {
            return <div key={error} className={'errors pl-4 mb-0'}>{error}</div>
          })}
          <div className='errors pl-4'>{this.props.errorForEmptyFields}</div>
        </Row>
        {this.renderLoanApplicationHeaderInfo()}
        <hr />
      </div>
    );
  }
}

export default LoanApplicationHeader;
