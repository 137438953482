import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// String
import { Strings } from "../../resources/i18n/i18n";

// Components
import LoanApproval from "./LoanApproval";
import LoadingModal from "../CustomUIComponents/LoadingModal/LoadingModal";

// Action
import { getApprovalLoansBySearchFilterAction } from "../LoanApproval/LoanApprovalAction";
import { setAnalystLoanDetailsIDAction, setEmiConfiguration } from "../LoanDetails/LoanDetailsAction";
import { clearDailyStatusDateAction } from "../DailyStatus/DailyStatusActions";
import { setUserAction } from "../UserList/UserListAction";
import { setSearchFilterAction } from "../../constants/Action";
import { getConfigTermEmi } from "../AnalystApproval/AnalystApprovalAction";

// Constants
import {
  USER_ROLES,
  SEARCH_FILTER_PAGES,
  LOAN_APPROVAL_FILTER,
  OPERATIONS_LOAN_APPROVAL_FILTER,
} from "../../constants/appConstants";
import { DEFAULT_PAGINATION_RECORDS_COUNT } from "../CustomUIComponents/Pagination/PaginationConstants";
import { OPERATION_STATUS } from "../../config/axios.init";
import { ROUTES } from "../../constants/routeConstants";

// Styles
import { Row } from "react-bootstrap";
import "../LoanList/LoanList.scss";
import Utility from "../../utils/Utility";

class LoanApprovalContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loans: [],
      numberOfPages: DEFAULT_PAGINATION_RECORDS_COUNT,
      showLoadingModal: false,
    };
    this.isCardClick = false;
  }

  componentDidMount() {
    if (
      this.props.loggedInUser.role === USER_ROLES.generalManager ||
      this.props.loggedInUser.role === USER_ROLES.analystModerator ||
      this.props.loggedInUser.role === USER_ROLES.admin ||
      ((this.props.loggedInUser.role ===
        USER_ROLES.operationsModerator ||
        this.props.loggedInUser.role === USER_ROLES.operationsManager) &&
        this.props.featureName === "OPERATIONS_OFFICER_DASHBOARD")
    ) {
      this.getUserList({
        filterRole:
          (this.props.loggedInUser.role ===
            USER_ROLES.operationsModerator ||
            this.props.loggedInUser.role === USER_ROLES.operationsManager)
            ? USER_ROLES.operationsOfficer
            : USER_ROLES.analyst,
      });
    }
    this.props.clearDailyStatusDateAction();
  }

  componentWillUnmount() {
    if (!this.isCardClick) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.LOAN_APPROVAL,
        { ...LOAN_APPROVAL_FILTER }
      );
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.OPERATIONS_LOAN_APPROVAL,
        { ...OPERATIONS_LOAN_APPROVAL_FILTER }
      );
    }
  }

  handleOnClickSearchFilter = (searchFilterObject) => {
    this.setState({
      showLoadingModal: true,
      searchFilter: {
        mid: searchFilterObject.search.matricesId,
        code: searchFilterObject.search.minimartCode,
        appid: searchFilterObject.search.applicationId,
        mn: searchFilterObject.search.minimartName,
        fd: searchFilterObject.search.fromDate,
        td: searchFilterObject.search.toDate,
        an: searchFilterObject.search.userName,
        st: searchFilterObject.search.status,
        ty: searchFilterObject.search.loanType,
        rst: searchFilterObject.search.remittanceStatus,
        cct: searchFilterObject.search.conciliationType,
        limit: searchFilterObject.limit,
        offset: searchFilterObject.offset,
      },
    });
    let isAnalyst =
      this.props.loggedInUser.role === USER_ROLES.analyst;
    let isOperationsOfficer =
      this.props.loggedInUser.role === USER_ROLES.operationsOfficer;
    let isOperationsModerator =
      this.props.loggedInUser.role === USER_ROLES.operationsModerator;
    let isAnalystModerator =
      this.props.loggedInUser.role === USER_ROLES.analystModerator;
    let isAuditor =
      this.props.loggedInUser.role === USER_ROLES.auditor;
    let isGeneralManager =
      this.props.loggedInUser.role === USER_ROLES.generalManager;
    let isAdmin = this.props.loggedInUser.role === USER_ROLES.admin;
    let isAccountsOfficer =
      this.props.loggedInUser.role === USER_ROLES.accountsOfficer;
    let isCustomerService =
      this.props.loggedInUser.role === USER_ROLES.customerService;
    let isOperationsManager =
      this.props.loggedInUser.role === USER_ROLES.operationsManager;
    let issalesManager =
      this.props.loggedInUser.role === USER_ROLES.salesManager;
    getApprovalLoansBySearchFilterAction(
      searchFilterObject,
      (response) => {
        if (response.status === OPERATION_STATUS.SUCCESS) {
          if (response.data.data.loans === null) {
            this.setState({
              loans: response.data.data.loansAnalystModerator
                ? response.data.data.loansAnalystModerator
                : [],
              numberOfPages: response.data.data.numberOfPages,
              showLoadingModal: false,
              errorMessage: "",
            });
          } else {
            this.setState({
              numberOfPages: response.data.data.numberOfPages,
              showLoadingModal: false,
              errorMessage: "",
              loans: response.data.data.loans
                ? response.data.data.loans
                : [],
            });
          }
        } else {
          this.setState({
            errorMessage: response.error.message,
            showLoadingModal: false,
          });
        }
      },
      isAnalyst,
      isOperationsOfficer,
      isOperationsModerator,
      isAccountsOfficer,
      isAnalystModerator,
      isAuditor,
      isGeneralManager,
      isAdmin,
      isCustomerService,
      isOperationsManager,
      issalesManager,
      this.props.featureName
    );
  };

  setLoanDetailsID = (loanDetails) => {
    this.setState({ showLoadingModal: true });
    this.isCardClick = true;
    this.props.featureName === "ANALYST_DASHBOARD"
      ? this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.LOAN_APPROVAL,
        this.state.searchFilter,
        SEARCH_FILTER_PAGES.LOAN_APPROVAL
      )
      : this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.OPERATIONS_LOAN_APPROVAL,
        this.state.searchFilter,
        SEARCH_FILTER_PAGES.OPERATIONS_LOAN_APPROVAL
      );
    this.props.setAnalystLoanDetailsIDAction(loanDetails);
    this.props.history.push(ROUTES.ANALYST_LOAN_VIEW);
  };

  /**
   * This function will give analyst names
   * @param {searchFilterObject} searchFilterObject
   */
  getUserList = (searchFilterObject) => {
    this.setState({ showLoader: true });
    const stateObject = Utility.getUserList(
      searchFilterObject,
      this.props.setUserAction
    );
    this.setState(stateObject);
  };

  getEMIConfiguration = () => {
    getConfigTermEmi("EMI", (Response) => {
      if (Response.status === OPERATION_STATUS.SUCCESS) {
        let loanConfig =
          Response.data.data.configurationObject.loanConfig;
        let levelConfig =
          Response.data.data.configurationObject.levelConfig;
        this.props.setEmiConfiguration(levelConfig, loanConfig);
      }
    });
  }

  render() {
    let userList = [];
    if (
      this.props.loggedInUser.role === USER_ROLES.analystModerator ||
      this.props.loggedInUser.role ===
      USER_ROLES.operationsModerator ||
      this.props.loggedInUser.role === USER_ROLES.operationsManager ||
      this.props.loggedInUser.role === USER_ROLES.generalManager ||
      this.props.loggedInUser.role === USER_ROLES.admin
    ) {
      userList = Utility.getAllUserList(
        this.props.users,
        this.props.loggedInUser.role ===
          USER_ROLES.analystModerator ||
          this.props.loggedInUser.role ===
          USER_ROLES.generalManager ||
          this.props.loggedInUser.role === USER_ROLES.admin
          ? Strings("analystName")
          : Strings("operationOfficerName")
      );
    }
    return (
      <div>
        <LoadingModal
          showLoadingModal={this.state.showLoadingModal}
        />
        <Row className='heading'>
          {Strings("LoanApprovalDashBoard")}{" "}
        </Row>
        <LoanApproval
          loans={this.state.loans}
          numberOfPages={this.state.numberOfPages}
          handleOnClickSearchFilter={this.handleOnClickSearchFilter}
          setLoanDetailsID={this.setLoanDetailsID}
          loggedInUser={this.props.loggedInUser}
          userList={userList}
          searchFilter={
            this.props.featureName === "ANALYST_DASHBOARD"
              ? this.props.loanApprovalSearchFilter
              : this.props.operationsLoanApprovalSearchFilter
          }
          featureName={this.props.featureName}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedInUser: state.loginReducer.loggedInUserInfo,
  users: state.userListReducer.users.users,
  loanApprovalSearchFilter:
    state.searchFilterReducer.loanApprovalSearchFilter,
  operationsLoanApprovalSearchFilter:
    state.searchFilterReducer.operationsLoanApprovalSearchFilter,
  featureName: state.sidebarReducer.selectedFeature.featureName,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setAnalystLoanDetailsIDAction,
      clearDailyStatusDateAction,
      setUserAction,
      setSearchFilterAction,
      setEmiConfiguration
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoanApprovalContainer);
